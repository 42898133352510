import React, { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { getGroupByUser, sendMessageToAllGroup, sendMessageWithGroupAndUser } from '../../../service/groupService';
import { SessionContext } from '../../../context/SessionContext';
import { showToast } from '../../commonUnit/Toast';
import { MenuItem, Select, Typography,Box } from '@mui/material';
import { filterProfanity, proFanityFilter } from '../../../tool/profanityFilter';
import { MessageContext } from '../../../context/MessageContext';
import '../../../css/chat.scss';
import { SelectedCodeContext } from "../../../context/SelectedCodeContext";
import { getSubmissionsBySessionId } from '../../../service/submissionService';
import { subscribeToAIMessage, sendMessageToAI, sendAIResponseToUser, getMessagesBetweenUserAndAI} from '../../../service/chatService';
import Loading from '../../commonUnit/Loading';
import axios from 'axios';
import Messages from '../Messages';
import { getSolution, addMessageType} from '../../../tool/aiAssistant';



const AIChat = () => {
  const initialPrompt = 
  `You are an upbeat, encouraging teaching assistant who helps students understand and python code and related concepts by answering student's question, explaining ideas and giving step-by-step instructions. 
        You must not directly write any code for them. You should guide students in an open-ended way. Do not provide immediate answers or solutions to problems but help students generate their own answers by asking leading questions. 
        Don't provide actual solution code anyway. And every time a student asks a question, you will be given the student's current code automatically with the student's question. 
        Keep your responses brief. Students will ask you questions while they are completing this task. 
        If they ask a broad question, you should divide it into several related concrete questions and ask them what aspect they want to know about, but if they ask about a specific part of the task, focus on that part of the task and do not extend into the other sections.\n 
        The function name much match what's given in the task description. Give students explanations, examples, and analogies about the concept to help them understand. 
        You should provide personalized feedback based on the student's code and question. 
        `
  const gptHost = process.env.REACT_APP_HOST + "/api/v1/gpt/openAI";

  const [prompt, setPrompt] = useState(initialPrompt);
  const [sortQuestion, setSortQuestion] = useState("For the question that the student ask for assistance regarding their code, sort the student's question into one of the following five categories:Task_clarification, Syntax_error_fix, Implementation_challenge, Failing_test_case_fix, Post_completion_code_refinement. Only include the category name in your response. If the student's question is irrelevant to completing the task, respond with 'irrelevant'.");
  const [chatData, setChatData] = useState([
    { role: "system", content: initialPrompt },
  ]);
  const { chatMessage, setChatMessage } = useContext(MessageContext);
  const { selectedCode, currentCode } = useContext(SelectedCodeContext);
  const { currentUser } = useContext(AuthContext);
  const { session } = useContext(SessionContext);
  const [receiver, setReceiver] = useState('assistant');
  const [keystrokes, setKeystrokes] = useState([]);
  const [solution, setSolution] = useState('');
  const [isLoadingAIResponse, setIsLoadingAIResponse] = useState(false);
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const chatHistoryRef = useRef(null);
  const [isChatDisabled, setIsChatDisabled] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [message , setMessage] = useState("");
  const [idleMessageSent, setIdleMessageSent] = useState(false);
  const [isChatUpdated, setIsChatUpdated] = useState(false);

  // useEffect(() => {
  //   if (isIdle && !idleMessageSent) {
  //     const sendIdleMessage = async () => {
  //       const idleMessage = "I noticed you've been idle for a while. Is everything okay? Do you need any help with your code?";
  //       await sendAIResponseToUser(idleMessage, currentUser, []);
  //       await addChatData(idleMessage, "assistant");
  //       setIdleMessageSent(true);
  //     };
  //     sendIdleMessage();
  //   }
  // }, [isIdle, idleMessageSent, currentUser]);
  

  useEffect(() => {
    if (session?.type) {
      if (session.type !== "Vizmental") {
        return;
      }
    }
  }, [session]);

  useEffect(() => {
    if (selectedCode !== null) {
      getSubmissionsBySessionId(session.id).then((submissions) => {
        const submission = submissions?.find((element) => element.code_id === selectedCode?.id);
      });
    }
  }, [selectedCode]);

  useEffect(() => {
    const updatePromptAndChatData = async () => {
      if (session?.task && session?.test_list) {
        const newPrompt = 
        `You are an upbeat, encouraging teaching assistant who helps students understand and python code and related concepts by answering student's question, explaining ideas and giving step-by-step instructions. 
        You must not directly write any code for them. Your responses should be plain English without any code, you could provide pseudocode.
        Don't provide any examples with code, but you can provide examples in plain English.
        You should guide students in an open-ended way. Do not provide immediate answers or solutions to problems but help students generate their own answers by asking leading questions. 
        Don't provide actual solution code anyway. And every time a student asks a question, you will be given the student's current code automatically with the student's question. 
        Keep your responses short.
        This is the task description: ${session.task} and the test cases: ${session.test_list}.
        If they ask a broad question, you should divide it into several related concrete questions and ask them what aspect they want to know about, but if they ask about a specific part of the task, focus on that part of the task and do not extend into the other sections.\n 
        When you breakdown the task, be sure not to provide any code. You should provide plain English explanations for each step.
        When you help students debug their code, you should provide plain English explanations for each step without any code.
        do noe give any examples with code, but you can provide examples in plain English!!!
        You should provide personalized feedback based on the student's code and question. 
        Limit your responses to at most 100 words.
        `;
        setChatData((prevMessages) => {
          const updatedMessages = prevMessages.filter((msg) => msg.role !== "system");
          return [{ role: "system", content: newPrompt }, ...updatedMessages];
        });
        // console.log("Updated chat data:", newPrompt);
        setPrompt(newPrompt);
        setIsChatDisabled(false);
        
      }
    };
    updatePromptAndChatData();
  }, [session, session.task, session.test_list]);

  useEffect(() => {
    if(!isChatOpen){
      const sendWelcomeMessage = async () => {
        const messages = await getMessagesBetweenUserAndAI(currentUser.id, session.id);
        // console.log("messages", messages);
        if( messages.length === 0){
          await sendAIResponseToUser("Hey I am your AI assistant, feel free to ask me questions if you are stuck!", currentUser, session.id, []);
          await addChatData("Hey I am your AI assistant, feel free to ask me questions if you are stuck!", "assistant");
          setIsChatUpdated(!isChatUpdated);
          setIsChatOpen(true);
        }
      }
      sendWelcomeMessage();
    }
  }, [isChatOpen]);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [displayedMessages]);

  useEffect(() => {
    const fetchMessages = async () => {
      const messages = await getMessagesBetweenUserAndAI(currentUser.id, session.id);
      setDisplayedMessages(messages);
    }
    fetchMessages();
  }, [isChatUpdated]);

  useEffect(() => {
    document.getElementById("myTextArea").value = chatMessage;
  }, [chatMessage]);


  if (!session) return null;
  if (!currentUser) return null;
  if (!currentUser.session_id) return <Loading />;

  const handleKeyPress = (e) => {
    if (isLoadingAIResponse) return;
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent newline character from being added
      sendMessage(e);
    }
  };

  const sendMessage = async (e) => {
    if(!isChatDisabled){
      e.preventDefault();
      if (chatMessage.trim()) {
        setIdleMessageSent(false);  
        const message = document.getElementById("myTextArea").value;
        setChatMessage('');
        setIsChatDisabled(true);
        if (receiver === 'assistant') {
          const res = await sendMessageToAI(message, currentUser, session.id, keystrokes);
          document.getElementById("myTextArea").value = "";
          setKeystrokes([]);
          await addChatData(message, "user");
          setIsChatUpdated(!isChatUpdated);
          const catagory = await axios.post(gptHost, { messages: [{role:"system", content: sortQuestion},{role: "user", content: message}] });
          await addMessageType(res.id, catagory.data.content);
          await askAI(message, selectedCode.content);
        }
        setIsChatDisabled(false);
        // //console.log(displayedMessages)
        // console.log(chatData)
      } else {
        showToast('Chat is disabled', 'warning');
      }
    }
  };

  const addChatData = async (newContent, newSender) => {
    setChatData(prevMessages => {
        const updatedMessages = [...prevMessages, { content: newContent, role: newSender }];
        return updatedMessages;
    });
};


const askAI = async (studentPrompt) => {
    const newPrompt = `${studentPrompt} This is the current code: ${currentCode}`;
    // console.log("newPrompt", newPrompt);

    setIsLoadingAIResponse(true);

    try {
      const res = await axios.post(gptHost, { messages: [...chatData,{role: "user", content: newPrompt}] });
      // //console.log("AIrespoonse:", res)
      // //console.log(res.data)
      const responseMessage = res.data.content;
      await sendAIResponseToUser(responseMessage, currentUser, session.id, []);
      
      await addChatData(responseMessage, "assistant");
      setIsChatUpdated(!!isChatUpdated);
      
    } catch (error) {
      console.error(error);
      addChatData("Sorry, something went wrong. Please try again.", "assistant");
    } finally {
      setIsLoadingAIResponse(false);
    }
};



  return (
    <>
    { ( !session?.enable_chat) ? 
      (<Typography variant="h6" style={{ textAlign: 'center', marginTop: '20px' }}>
      AI Chat is currently disabled for this session.
    </Typography>) :
    
    (
    
    <div className="chat-container" style={{ height: '100%' }}>
      <div className="chat" style={{ height: '100%' }}>
        <div className="chat-history" ref={chatHistoryRef}>
          <Messages messages={displayedMessages} />
        </div>
        <form className="message-form">
        <textarea
          className="message-input"
          id="myTextArea"
          placeholder= {isChatDisabled ? 'Generating...' :'Use Shift + Enter for new line'}
            onKeyDown={handleKeyPress}
          />
          <button
            type="submit"
            className="send-button"
            onClick={sendMessage}
            disabled={isChatDisabled}
            style={{
              backgroundColor: isChatDisabled ? '#efefef' : '#1976d2',
              cursor: isChatDisabled ? 'not-allowed' : 'pointer'
            }}
          >
            Send
          </button>
        </form>
      </div>
    </div>
    )
    
    }
    </>
  )
}

export default AIChat;
