import React, { useState, useContext, useEffect } from 'react';
import Modal from '@mui/joy/Modal';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import ModalClose from '@mui/joy/ModalClose';
import ModalOverflow from '@mui/joy/ModalOverflow';
import ModalDialog from '@mui/joy/ModalDialog';
import Divider from '@mui/joy/Divider';
import Box from '@mui/joy/Box';
import Avatar from '@mui/joy/Avatar';
import CircularProgress from '@mui/joy/CircularProgress';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Alert from '@mui/joy/Alert';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import { AuthContext } from '../../context/AuthContext';
import { getUserById, updateUser } from '../../service/userService';
import { updateInstructor, getInstructorById } from '../../service/instructorService';
import { 
  sendRecoveryEmail, 
  changeInstructorPassword,
  changeStudentPassword,
} from '../../service/authService';
import AvatarSelectionModal from './AvatarSelectionModal';

// Import all avatar images
import avatarM1 from '../../icon/avatars/avatarM1.jpg';
import avatarF1 from '../../icon/avatars/avatarF1.jpg';
import avatarM2 from '../../icon/avatars/avatarM2.jpg';
import avatarF2 from '../../icon/avatars/avatarF2.jpg';
import avatarM3 from '../../icon/avatars/avatarM3.jpg';
import avatarF3 from '../../icon/avatars/avatarF3.jpg';
import avatarM4 from '../../icon/avatars/avatarM4.jpg';
import avatarF4 from '../../icon/avatars/avatarF4.jpg';
import avatarM5 from '../../icon/avatars/avatarM5.jpg';
import avatarF5 from '../../icon/avatars/avatarF5.jpg';

const ProfileModal = ({ open, onClose }) => {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profileError, setProfileError] = useState(null);
  const [profileSuccess, setProfileSuccess] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [passwordSuccess, setPasswordSuccess] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    nickname: '',
    firstName: '',
    lastName: '',
    pronouns: '',
    avatar_url: ''
  });
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);

  const isStudent = currentUser && (currentUser.role === 2 || currentUser.role === 3);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (open && currentUser && currentUser.id) {
        setLoading(true);
        setProfileError(null);
        setProfileSuccess(null);
        try {
          let user;
          if (currentUser.role === 2 || currentUser.role === 3) {
            // For students
            user = await getUserById(currentUser.id);
          } else {
            // For instructors
            user = await getInstructorById(currentUser.id);
          }
          setUserDetails(user);
          setFormData({
            nickname: user.nick_name || '',
            firstName: user.first_name || '',
            lastName: user.last_name || '',
            pronouns: user.pronouns || '',
            avatar_url: user.avatar_url || ''
          });
        } catch (err) {
          console.error('Error fetching user details:', err);
          setProfileError('Failed to load user details');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserDetails();
  }, [open, currentUser]);

  const handleInputChange = (field) => (e) => {
    setFormData(prev => ({ ...prev, [field]: e.target.value }));
  };

  const handlePasswordInputChange = (field) => (e) => {
    setPasswordData(prev => ({ ...prev, [field]: e.target.value }));
  };

  const handleSaveChanges = async () => {
    if (!currentUser) {
      setProfileError('User information not available');
      return;
    }

    if (isStudent && !formData.nickname.trim()) {
      setProfileError('Display Name cannot be empty');
      return;
    }

    setLoading(true);
    setProfileError(null);
    setProfileSuccess(null);
    try {
      const updatedUser = {
        id: currentUser.id,
        nick_name: formData.nickname,
        first_name: formData.firstName,
        last_name: formData.lastName,
        pronouns: formData.pronouns,
        avatar_url: formData.avatar_url
      };

      let result;
      if (isStudent) {
        result = await updateUser(updatedUser);
      } else {
        result = await updateInstructor(updatedUser);
      }

      if (result) {
        setUserDetails(prev => ({...prev, ...updatedUser}));
        setCurrentUser(prev => ({...prev, ...updatedUser}));
        setProfileSuccess('Profile updated successfully');
        setEditMode(false);
      } else {
        setProfileError('Failed to update profile');
      }
    } catch (err) {
      console.error('Error updating user details:', err);
      setProfileError('Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    setPasswordError(null);
    setPasswordSuccess(null);

    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      setPasswordError("New passwords don't match");
      return;
    }

    if (passwordData.newPassword.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      return;
    }

    setLoading(true);
    try {
      if (isStudent) {
        await changeStudentPassword(passwordData.oldPassword, passwordData.newPassword, currentUser.id);
      } else {
        await changeInstructorPassword(passwordData.oldPassword, passwordData.newPassword, currentUser.email);
      }
      setPasswordSuccess('Password changed successfully');
      setPasswordModalOpen(false);
      setPasswordData({oldPassword: '', newPassword: '', confirmNewPassword: ''});
    } catch (err) {
      console.error('Error changing password:', err);
      setPasswordError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSendRecoveryEmail = async () => {
    setLoading(true);
    setPasswordError(null);
    setPasswordSuccess(null);
    try {
      await sendRecoveryEmail(userDetails.email);
      setPasswordSuccess('Password reset email sent. Please check your inbox.');
      setPasswordModalOpen(false);
    } catch (err) {
      console.error('Error sending password reset email:', err);
      setPasswordError('Failed to send password reset email');
    } finally {
      setLoading(false);
    }
  };

  const handleAvatarSelect = (avatarFilename) => {
    const avatarMap = {
      'avatarM1.jpg': avatarM1,
      'avatarF1.jpg': avatarF1,
      'avatarM2.jpg': avatarM2,
      'avatarF2.jpg': avatarF2,
      'avatarM3.jpg': avatarM3,
      'avatarF3.jpg': avatarF3,
      'avatarM4.jpg': avatarM4,
      'avatarF4.jpg': avatarF4,
      'avatarM5.jpg': avatarM5,
      'avatarF5.jpg': avatarF5,
    };

    const avatarUrl = avatarMap[avatarFilename];

    if (avatarUrl) {
      setFormData(prev => ({ ...prev, avatar_url: avatarUrl }));
      setAvatarModalOpen(false);
    } else {
      console.error('Unknown avatar selected:', avatarFilename);
    }
  };

  const handleOpenPasswordModal = () => {
    setPasswordModalOpen(true);
    setPasswordError(null);
    setPasswordSuccess(null);
    setPasswordData({oldPassword: '', newPassword: '', confirmNewPassword: ''});
  };

  const renderField = (label, field) => {
    // Don't render the "Display Name" field for instructors
    if (!isStudent && field === 'nickname') {
      return null;
    }

    return (
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <Input
          value={formData[field]}
          onChange={handleInputChange(field)}
          readOnly={!editMode}
          sx={{
            ...((!editMode) && {
              '&:hover': {
                cursor: 'default',
              },
              '& .MuiInput-input': {
                cursor: 'default',
                caretColor: 'transparent',
              },
              '&:before, &:after, &:hover:not(.Mui-disabled):before': {
                borderBottom: 'none',
              },
              '&.Mui-focused': {
                boxShadow: 'none',
              },
            }),
            ...(editMode && {
              '&:hover': {
                borderColor: 'primary.main',
              },
              '&.Mui-focused': {
                borderColor: 'primary.main',
                boxShadow: '0 0 0 2px rgba(25, 118, 210, 0.2)',
              },
            }),
          }}
        />
      </FormControl>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
          setProfileError(null);
          setProfileSuccess(null);
          setEditMode(false);
        }}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <ModalOverflow>
          <ModalDialog
            aria-labelledby="profile-modal-title"
            layout="center"
            size="lg"
            variant="outlined"
          >
            <Sheet
              sx={{
                borderRadius: 'md',
                p: 3,
                boxShadow: 'sm',
              }}
            >
              <ModalClose
                variant="outlined"
                sx={{
                  top: 'calc(-1/4 * var(--IconButton-size))',
                  right: 'calc(-1/4 * var(--IconButton-size))',
                  boxShadow: 'sm',
                  borderRadius: '50%',
                  bgcolor: 'background.surface',
                }}
              />
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                  <CircularProgress />
                </Box>
              ) : userDetails ? (
                <>
                  <Typography level="h4" sx={{ mb: 2, textAlign: 'center' }}>
                    {isStudent ? 'Student' : 'Instructor'} Profile
                  </Typography>
                  <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar
                      src={formData.avatar_url}
                      alt={userDetails.first_name}
                      sx={{
                        width: 100,
                        height: 100,
                        border: '4px solid',
                        borderColor: 'background.surface',
                        boxShadow: 'sm',
                        mb: 2
                      }}
                    />
                    {editMode && (
                      <Button 
                        onClick={() => setAvatarModalOpen(true)} 
                        variant="outlined" 
                        color="neutral"
                        size="sm"
                        sx={{
                          transition: 'all 0.2s ease-in-out',
                          '&:hover': {
                            transform: 'scale(1.05)',
                          },
                        }}
                      >
                        Change Avatar
                      </Button>
                    )}
                  </Box>
                  {profileSuccess && (
                    <Alert color="success" sx={{ mb: 2 }}>
                      {profileSuccess}
                    </Alert>
                  )}
                  {profileError && (
                    <Alert color="danger" sx={{ mb: 2 }}>
                      {profileError}
                    </Alert>
                  )}
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ display: 'grid', gap: 2 }}>
                    {isStudent && renderField('Display Name', 'nickname')}
                    {renderField('First Name', 'firstName')}
                    {renderField('Last Name', 'lastName')}
                    {renderField('Pronouns', 'pronouns')}
                    {!isStudent && (
                      <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input value={userDetails.email} readOnly />
                      </FormControl>
                    )}
                  </Box>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                    <Button 
                      onClick={() => editMode ? handleSaveChanges() : setEditMode(true)} 
                      variant="solid" 
                      color={editMode ? "success" : "primary"}
                      sx={{
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        },
                      }}
                    >
                      {editMode ? 'Save Changes' : 'Edit Profile'}
                    </Button>
                    <Button 
                      onClick={handleOpenPasswordModal} 
                      variant="outlined" 
                      color="neutral"
                      sx={{
                        ml: 2,
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        },
                      }}
                    >
                      Change Password
                    </Button>
                  </Box>
                </>
              ) : (
                <Typography>No user data available</Typography>
              )}
            </Sheet>
          </ModalDialog>
        </ModalOverflow>
      </Modal>

      <Modal
        open={passwordModalOpen}
        onClose={() => setPasswordModalOpen(false)}
      >
        <ModalDialog
          aria-labelledby="password-change-modal"
          layout="center"
          size="sm"
          variant="outlined"
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: 'calc(-1/4 * var(--IconButton-size))',
              right: 'calc(-1/4 * var(--IconButton-size))',
              boxShadow: 'sm',
              borderRadius: '50%',
              bgcolor: 'background.surface',
            }}
          />
          <Typography level="h4" sx={{ mb: 2 }}>Change Password</Typography>
          {passwordError && (
            <Alert color="danger" sx={{ mb: 2 }}>
              {passwordError}
            </Alert>
          )}
          {passwordSuccess && (
            <Alert color="success" sx={{ mb: 2 }}>
              {passwordSuccess}
            </Alert>
          )}
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Current Password</FormLabel>
              <Input
                type="password"
                value={passwordData.oldPassword}
                onChange={handlePasswordInputChange('oldPassword')}
              />
            </FormControl>
            <FormControl>
              <FormLabel>New Password</FormLabel>
              <Input
                type="password"
                value={passwordData.newPassword}
                onChange={handlePasswordInputChange('newPassword')}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Confirm New Password</FormLabel>
              <Input
                type="password"
                value={passwordData.confirmNewPassword}
                onChange={handlePasswordInputChange('confirmNewPassword')}
              />
            </FormControl>
            <Button 
              onClick={handleChangePassword} 
              variant="solid" 
              color="primary" 
              disabled={loading}
              sx={{
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              {loading ? <CircularProgress size="sm" /> : 'Change Password'}
            </Button>
            <Divider>OR</Divider>
            <Button 
              onClick={handleSendRecoveryEmail} 
              variant="outlined" 
              color="neutral" 
              disabled={loading}
              sx={{
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              Forgot Password? Send Recovery Email
            </Button>
          </Stack>
        </ModalDialog>
      </Modal>

      <AvatarSelectionModal
        open={avatarModalOpen}
        onClose={() => setAvatarModalOpen(false)}
        onSelect={handleAvatarSelect}
        currentAvatar={formData.avatar_url}
      />
    </>
  );
}

export default ProfileModal;