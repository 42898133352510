import React, { useContext } from 'react'
import 'react-circular-progressbar/dist/styles.css'
import { SubmissionsContext } from '../../context/SubmissionsContext'
import { SessionContext } from '../../context/SessionContext'
import { getPassrateOfClass } from '../../service/submissionService'
import { useState } from 'react'
import { useEffect } from 'react'
import { Switch, TextField, Typography } from '@mui/material'
import { groupingInSession, getUsersSortedByProgress } from '../../service/sessionService'
import { extractErrorInfo } from '../../service/errorService' 
import { LineChart } from '@mui/x-charts/LineChart';
import {
  getUserSubmissions,
  getSessionSubmissions,
 } from '../../service/submissionService'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { TestPanelContext } from '../../context/TestPanelContext'
import '../../css/passrate.scss'
import { getUserById, getUserInSession,getAllUsersInSession } from '../../service/userService'
import { AuthContext } from '../../context/AuthContext'
import { getCodeById, getCodesByIds} from '../../service/codeService'
import { Alert } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import Link from '@mui/material/Link'
import { Button } from '@mui/material'
import { SelectedCodeContext } from '../../context/SelectedCodeContext'
import { AutoGroupingContext } from '../../context/AutoGroupingContext'
import CodeIssueListItem from "./CodeIssueListItem";
import InteractionList from './InteractionList'

const PassRateTable = ({}) => {
  const { session } = useContext(SessionContext)
  const { submissions } = useContext(SubmissionsContext)
  const [passRate, setPassRate] = useState(0)
  const { autoGrouping } = useContext(AutoGroupingContext)
  const { autoGroupingRate } = useContext(AutoGroupingContext)
  const [passRateList, setPassRateList] = useState([])
  const [testNameList, setTestNameList] = useState([])
  const [classAttempts, setClassAttempts] = useState([])
  const [userDict, setUserDict] = useState({})

  const {
    studIndex,
    unitTestName,
    testPanel,
    testName,
    setTestLength,
    setTestPanel,
    setTestName,
    studName,
    setStudName,
    setStudPassArray,
    studPassArray,
    setStudIndex,
  } = useContext(TestPanelContext)
  const { currentUser } = useContext(AuthContext)
  const [rerender, setRerender] = useState(false)
  const [correctArray, setCorrectArray] = useState([])
  const [delay, setDelay] = useState(0)
  const { setSelectedCode } = useContext(SelectedCodeContext)
  const [testCases, setTestCases] = useState([])



  useEffect(() => {
    console.log(session.active_stu)
    if (!session.test_list || session.test_list.length === 0) return
    const rate = getPassrateOfClass(
      session.stu_num,
      session.test_list.length,
      submissions
    )

    if (autoGrouping && !session.grouped && autoGroupingRate) {
      if (rate >= autoGroupingRate) {
        groupingInSession(session, 'passrate')
      }
    }

    if (session.test_list.length > 0) {
      const passRateList = new Array(session.test_list.length).fill(0)
      submissions.forEach((submission) => {
        //tally individual tests passed
        if (!submission.result_list) return
        submission.result_list.forEach((result, index) => {
          if (result) {
            passRateList[index] += 1
          }
        })
      })
      //set all test pass rate
      let allTestPass = Math.min(...passRateList)
      passRateList.push(allTestPass)
      setPassRateList([...passRateList, allTestPass])
      const testNameList = session.test_list.map((test) => ({
        testname: test.testName,
        passrate: passRateList[session.test_list.indexOf(test)],
      }))
      setTestNameList([
        ...testNameList,
        {
          testname: 'All',
          passrate: passRateList[session.test_list.length],
        },
      ])
    }

    setPassRate(rate)
    fetchNotPassedSubmissions()
  }, [submissions, session])
  
  const fetchNotPassedSubmissions = async () => {
		let notPassedSubmissions = submissions.filter(
			(submission) =>
				submission.result_list &&
				submission.result_list.length !== 0
		);
    let cases=[]
    session.test_list.forEach((test) => {
      cases.push({
        errorType: test.testName,
        errorContents: [],
        count: 0,
        users: [],
        codeids: []
      })
    })
    cases.push({
      errorType: "All",
      errorContents: [],
      count: 0,
      users: [],
      codeids: []
    })
		for (const submission of notPassedSubmissions) {
      let user = await getUserById(submission.user_id)
      let error = false
			for (let [index, val] of submission?.result_list?.entries()) {
        if(!val){
          error = true
          // cases[index].errorContents.push(
          //   user.first_name + " Failed"
          // )
          // cases[index].users.push(submission.id)
          // cases[index].codeids.push(submission.code_id);
        }
        else{
          cases[index]?.errorContents?.unshift(
            user.first_name + " Passed"
          )
          cases[index].count += 1;
          cases[index].users.unshift(submission.id)
          cases[index].codeids.unshift(submission.code_id);
        }
      }
      if(!error){
        cases[session.test_list.length].errorContents.unshift(
          user.first_name + " Passed"
        )
        cases[session.test_list.length].count += 1;
        cases[session.test_list.length].users.unshift(submission.id)
        cases[session.test_list.length].codeids.unshift(submission.code_id);
      }
      else{
        // cases[session.test_list.length].errorContents.push(
        //   user.first_name + " Failed"
        // )
        // cases[session.test_list.length].users.push(submission.id)
        // cases[session.test_list.length].codeids.push(submission.code_id);
      }
        
		}
    // //console.log(submissions)
    // //console.log(notPassedSubmissions)
    // //console.log(cases)
    setTestCases(cases)
    
	};
  useEffect(() => {
    if (autoGrouping && !session.grouped && autoGroupingRate) {
      if (passRate >= autoGroupingRate) {
        groupingInSession(session, 'passrate')
      }
    }
  }, [autoGrouping, autoGroupingRate])

  useEffect(() => {
    try {
      fetchClassStats()
    } catch (error) {
      
    }
    const createNameListArray = async (index) => {
      //creates array of name objects that only contains name and code id of students that
      //passed the unit test
      const nameList = []
      submissions?.forEach(async (submission) => {
        if (index === passRateList.length - 1) {
          const code = await getCodeById(submission.code_id)
          const codeUser = await getUserById(code?.creater_id)
          nameList.push({
            name: codeUser.name,
            code_id: submission.code_id,
            pass: true,
          })
        } else {
          const code = await getCodeById(submission.code_id)
          const codeUser = await getUserInSession(session.id, code?.creater_id)
          nameList.unshift({
            name: codeUser?.name,
            code_id: submission.code_id,
            pass: false,
          })
        }
      })
      await sleep(1000)
      setCorrectArray(nameList)
    }

    createNameListArray(studIndex)
  }, [studIndex, submissions, session])

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const handleClick = () => {
    setTestPanel(!testPanel)
  }

  const rowClickHandler = async (unitTestName, index) => {
    setTestName(unitTestName)
    setStudIndex(index)
    ////console.log("submissions",submissions);
    setRerender(!rerender)
    setTestPanel(!testPanel)
  }
  const fetchClassStats = async () => {
    let dictSubCode = {}
    let classSubs = await getSessionSubmissions(session.id)
    if(classSubs === undefined || classSubs?.length === 0) return
    for(let i = 0; i < classSubs?.length; i++){
      dictSubCode[classSubs[i].code_id] = {submission:classSubs[i]}
    }
    let code_ids = classSubs.map((sub)=>sub.code_id)
    //console.log(classSubs)
    let classCodes = await getCodesByIds(code_ids)
    for(let i = 0; i < classCodes?.length; i++){
      dictSubCode[classCodes[i].id]["code"] = classCodes[i]
    }
    let users = await getUsersSortedByProgress(session)
    console.log(users)
    let dictUser = {}
    for(let i = 0; i < users?.length; i++){
      dictUser[users[i].id] = users[i]
    }
    console.log(dictUser)
    setUserDict(dictUser)
    //console.log(Object.values(dictSubCode))
    let dataset = Object.values(dictSubCode).map((item)=>{
      let obj = {date: new Date(item.code.created_time)}
      obj[item.code.creater_id] = item.code.passrate
      obj["code"] = item.code
      obj["submission"] = item.submission
      obj["user"] = dictUser[item.code.creater_id]
      return obj
    })
    setClassAttempts(dataset)
    console.log(dataset)
  }
  const clickCode = (event,params) => {
    //console.log(classAttempts[params.dataIndex].code.id)
    getCodeById(classAttempts[params.dataIndex].code.id).then((newCode) => {
      //console.log(newCode)
      setSelectedCode(newCode)
    })
  }
  //fetchClassStats()
  const handleInspectCode = async (index, codeId) => {
    const code = await getCodeById(codeId)
    await setSelectedCode(code)
  }
  ////console.log(testNameList)
  return (
    <>
    <div className="group-info-test">
      <div style={{display:"flex", justifyContent:"space-between"}}>
        <Typography variant="h6" fontWeight={'light'}>
          Class Performance
        </Typography>
        <Typography variant="h6" fontWeight={'light'}>
          Active Students: {session.active_stu}
        </Typography>
      </div>
{/* 
      <Typography variant="h6" fontWeight={'light'}>
          Test Cases Summary
      </Typography> */}
      {true && (
        <LineChart
        dataset={classAttempts} 
        xAxis={[
          {
            id: 'Time',
            dataKey: "date",
            scaleType: 'time',
            valueFormatter: (date) => date.toLocaleTimeString(),
          },
        ]}
        series={submissions.map((sub)=>{
          return {
            id: sub.user_id,
            label: (location)=>{
              if(location == "legend"){
                return
              }
              else{
                return userDict[sub.user_id]?.first_name
              }
            },
            dataKey: sub.user_id,
            connectNulls:true,
            valueFormatter: (v, { dataIndex }) => {
              if(v==null){
                return
              }
              return (

                <div>
                  <pre>Passrate: {v}</pre>
                  {classAttempts[dataIndex]?.submission.error!=null && v!=null && (
                    <pre>Error: {extractErrorInfo(classAttempts[dataIndex]?.submission.error)?.errorContent}</pre>
                  )}
                  
                
                </div>
              )
            },
          }
        })}
        onMarkClick={clickCode}

      />
      )}
      
      {testCases.map((test, index) => (
        <div>
          <CodeIssueListItem
            error={test.errorType}
            message={test.errorType}
            content={test.errorContents}
            total_num={session.stu_num}
            error_num={test.count}
            code_id={test.codeids}
          />
        </div>
      )) }

        

    </div>
    {session?.type === "Vizmental" && <InteractionList/> }
      </>
  )
}

export default PassRateTable
