import React, { useState, useEffect, useContext } from 'react';
import { Card, CardContent, CardHeader, Typography, Box } from '@mui/material';
import { getAllAnswersBySessionId } from '../../service/chatService';
import { SessionContext } from '../../context/SessionContext';
import { getAnswerCharts } from '../../service/gptService'

const ClassPerformance = ({}) => {
  const { session } = useContext(SessionContext);
  const [allAnswers, setAllAnswers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [discussionResults, setDiscussionResults] = useState([]);

  // Map the question numbers to the actual questions
  const questionTextMap = {
    1: "I believe that the rapid advancements in generative AI outpace current copyright laws, leading to significant challenges in protecting intellectual property.",
    2: "I think that the existing legal frameworks are insufficient to address the complexities of copyright issues arising from generative AI technology.",
    3: "I believe that generative AI can enhance creativity without infringing on copyright, provided there are clear definitions of fair use."
  };

  useEffect(() => {
    const fetchAllAnswers = async () => {
      try {
        setLoading(true);
        const data = await getAllAnswersBySessionId(session.id);
        setAllAnswers(data);
        console.log('testing chatgpt on the frontend for question 1')
        getAnswerCharts(data, 1)
        processAnswers(data);
        setLoading(false);
      } catch (err) {
        setError(`Failed to load discussion answers: ${err.message}`);
        setLoading(false);
      }
    };

    if (session.id) {
      fetchAllAnswers();
    }
  }, [session.id]);

  const processAnswers = (answers) => {
    const questionCount = {}; // to store counts of agree and disagree answers

    // Loop through all answers and group them by question_number
    answers.forEach(answer => {
      const { question_number, content } = answer;

      if (!questionCount[question_number]) {
        questionCount[question_number] = { agree: 0, disagree: 0, total: 0 };
      }

      // Check if the content ends with "agree" or "disagree" and count accordingly
      if (content.endsWith('agree')) {
        questionCount[question_number].agree += 1;
      } else if (content.endsWith('disagree')) {
        questionCount[question_number].disagree += 1;
      }

      questionCount[question_number].total += 1; // Total responses for the question
    });

    // Calculate the percentage for agree and disagree answers
    const result = Object.keys(questionCount).map(questionNum => {
      const { agree, disagree, total } = questionCount[questionNum];
      return {
        question: `Question ${questionNum}`, // Display "Question 1", "Question 2", etc. on the X-axis
        fullQuestion: questionTextMap[questionNum], // Store the full question for tooltip
        agreePercent: (agree / total) * 100,
        disagreePercent: (disagree / total) * 100,
        agreeCount: agree, // Store the raw number of students who agreed
        disagreeCount: disagree // Store the raw number of students who disagreed
      };
    });

    setDiscussionResults(result);
  };

  const renderTooltip = (data) => {
    if (!data || !data.active || !data.payload) return null;
    const payload = data.payload[0]?.payload;
    const question = payload?.fullQuestion;
    const agreeCount = payload?.agreeCount;
    const disagreeCount = payload?.disagreeCount;

    return (
      <Box sx={{ bgcolor: 'white', p: 2, border: '1px solid gray' }}>
        <Typography variant="body2">{question}</Typography>
        <Typography variant="body2">
          <strong>Agreed:</strong> {agreeCount} students
        </Typography>
        <Typography variant="body2">
          <strong>Disagreed:</strong> {disagreeCount} students
        </Typography>
      </Box>
    );
  };

  if (loading) return <Typography>Loading answers... (Session ID: {session.id})</Typography>;
  if (error) return <Typography color="error">Error: {error}</Typography>;
  if (!allAnswers || allAnswers.length === 0) return <Typography>No answers found for this session.</Typography>;

  return (
    <Box sx={{ p: 4, bgcolor: 'background.default' }}>
      <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }}> {/* Centered heading */}
        Class Performance Dashboard
      </Typography>

      
    </Box>
  );
};

export default ClassPerformance;