import React, { useState, useEffect, useContext } from 'react'
import { getLatestNotification, updateNotification } from '../../service/notificationService'
import { SessionContext } from '../../context/SessionContext'
import { AuthContext } from '../../context/AuthContext'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import "../../css/notification.scss"
import { TextField, Card, Button } from "@mui/material";

const Notification = () => {
    const { session } = useContext(SessionContext)
    const { currentUser } = useContext(AuthContext)
    const [notification, setNotification] = useState(null)
    const [show, setShow] = useState(false) // Show notification by default
    const [expanded, setExpanded] = useState(false)
    const [loading, setLoading] = useState(true)
    const [helpfulness, setHelpfulness] = useState(null) 
    const [comment, setComment] = useState('')
    const [hasRated, setHasRated] = useState(false) 
    const [ratingMessage, setRatingMessage] = useState('') 
    const [id, setId] = useState('')

    useEffect(() => {
        if (!session || !currentUser) return
        getInitialNotification()
    }, [session, currentUser])

    useEffect(() => {
        const interval = setInterval(() => {
            getNotification()
        }, 5000)
        return () => clearInterval(interval)
    }, [session, currentUser])

    const getInitialNotification = async () => {
        const notification = await getLatestNotification(session.id, currentUser.id)
        
        if (notification && notification.id) {
            setNotification(notification)
            setId(notification.id)
            setLoading(false)
    
            if (notification.review) {
                setHelpfulness(notification.review.helpfulness)
                setComment(notification.review.optional_comments || '')
                setHasRated(true)
            } 
            
        }
        setLoading(false)
    }


    const getNotification = async () => {
        const notification = await getLatestNotification(session.id, currentUser.id)
        
        if (notification && notification.id && notification.id !== id && !notification.viewed) {
            setNotification(notification)
            setId(notification.id)
            setLoading(false)
            setShow(true)
    
            if (notification.review) {
                setHelpfulness(notification.review.helpfulness)
                setComment(notification.review.optional_comments || '')
                setHasRated(true)
                console.log("found review")
            } else {
                setHelpfulness(null)
                setComment('')
                setHasRated(false)
                console.log("no review found")
            }
        }
        setLoading(false)
    }

    const handleExpand = async() => {
        setExpanded(true)
        await markAsViewed()
    }
    
    const handleCollapse = async() => {
        if(expanded){setExpanded(false)}
        await markAsViewed()
    }
    const handleMinimize = () => {
        if(expanded){setExpanded(false)}
        
        if(show){setShow(false)}
        if(!show){setShow(true)}
    }
    const markAsViewed = async () => { 
        const updatedNotification = {
            ...notification,
            viewed: true,
            view_time: new Date(),
        }
        updateNotification(updatedNotification)
        setNotification(updatedNotification) 
    }


    const handleSubmitRating = async () => {
        const reviewData = {
            rate_time: new Date(),
            helpfulness,
            optional_comments: comment,
        }
        
        const updatedNotification = {
            ...notification,
            review: reviewData,
            rate_time: new Date(),
        }
        
        await updateNotification(updatedNotification)
        setNotification(updatedNotification) 
        
        setHasRated(true)
        setRatingMessage( "Thank you for your review!")
        setTimeout(() => {
            setRatingMessage('')
        }, 3000)
    }

    const renderNotificationContent = () => {
        if (!notification) {
            return <div className="notification-container"> <div className="notification-empty">No new notification</div> </div>
        }
        
        const preview = notification.content.length <= 50 
        ? notification.content 
        : notification.content.slice(0, 50) + '...';
    
        return (
            <div className="notification-container">
                <div className="notification-header">
                    <p>Instructor Feedback</p>
                    {!notification.viewed && <span className="notification-dot"></span>}
                    <button onClick={handleMinimize} className="minimize-button">
                        Minimize
                    </button>
                </div>
                
                {expanded ? (
                    <div className="notification-full">
                        <span>{notification.content}</span>

                        { !notification.review && (<div className="rating-section">
                            Was this feedback helpful?
                            <div className="thumb-buttons">
                                <ThumbUpIcon
                                    className="thumb-up"
                                    onClick={() => setHelpfulness('up')}
                                    style={{
                                        color: helpfulness === 'up' ? '#4caf50' : '#888',
                                        cursor: 'pointer',
                                        fontSize: '1.5rem'
                                    }}
                                />
                                <ThumbDownIcon
                                    className="thumb-down"
                                    onClick={() => setHelpfulness('down')}
                                    style={{
                                        color: helpfulness === 'down' ? '#f44336' : '#888',
                                        cursor: 'pointer',
                                        fontSize: '1.5rem'
                                    }}
                                />
                            </div>
                            <TextField
                                label="Optional Comments"
                                multiline
                                rows={3}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                variant="outlined"
                                style={{ width: '90%' }}
                            />
                            <button 
                                onClick={handleSubmitRating}
                                className="submit-button"
                            >
                                Submit
                            </button>
                            
                            
                        </div>)}
                        <button 
                                onClick={handleCollapse}
                                className="submit-button"
                            >
                                Collapse
                            </button>
                        {ratingMessage && <p className="rating-message">{ratingMessage}</p>}
                        
                    </div>
                ) : (
                    <div className="notification-preview">
                        <span>{preview}</span>
                        <button 
                            onClick={handleExpand}
                            className="expand-button"
                        >
                            Expand
                        </button>
                    </div>
                )}
            </div>
        )
    }

    return (
        <>
        <button 
                onClick={() => setShow(!show)} 
                className="notification-button"
            >
                SHOW NOTIFICATION
        </button>
        {show && (
            <Card className="notification-wrapper show">
                {renderNotificationContent()}
            </Card>
        )} 
            
        
        </>
    )
}

export default Notification
