import React, { useEffect, useState } from 'react';
import '@fontsource/inter';
import Modal from '@mui/joy/Modal';
import { Button } from '@mui/material';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Sheet';
import ModalClose from '@mui/joy/ModalClose';
import Grid from '@mui/joy/Grid';
import Divider from '@mui/joy/Divider';
import autoGroup from '../../icon/SettingPassRate.png';
import '../../css/landing/aboutTutorial.scss';
import blockly from '../../icon/about/Blockly.png'
import autoGroupFeature from '../../icon/about/tutorial/autoGrouping/features.png';
import autoGroupStudent from '../../icon/about/tutorial/autoGrouping/student.png';
import autoGroupInstructor from '../../icon/about/tutorial/autoGrouping/instructor.png';
import vizmentalStudent from '../../icon/about/tutorial/vizmental/student.png';
import vizmentalInstructor from '../../icon/about/tutorial/vizmental/instructor.png';
import helperStudent1 from '../../icon/about/tutorial/helper/student1.png';
import helperStudent2 from '../../icon/about/tutorial/helper/student2.png';
import helperInstructor from '../../icon/about/tutorial/helper/instructor.png';
import audioStudent from '../../icon/about/tutorial/audioDiscussion/student.png';
import audioInstructor from '../../icon/about/tutorial/audioDiscussion/instructor.png';
import { Link, useNavigate } from 'react-router-dom';




const contentArrays = {
    "Auto Grouping": [
        {
            title: 'Exersice Features',
            content: [
                {
                    text: "A peer instruction coding activity where students are auto-grouped based on performance for collaborative discussion.",
                    images: [autoGroupFeature]
                },
            ]
        },
        {
            title: 'Student Side',
            content: [
                {
                    text: "Similar to peer instruction, students begin by working independently on a coding problem. They are then automatically grouped into teams of 3 or 4 based on their performance, where they can interact via a chat interface to discuss their code. This exercise currently supports Python, Blockly, Java, and MySQL.",
                    images: [autoGroupStudent]
                }
            ]
        },
        {
            title: 'Instructor Side',
            content: [
                {
                    text: "Instructors can monitor students' coding performance and discussion activities in real-time via a dashboard, including topics discussed and engagement levels. This exercise helps instructors identify group dynamics and individual contributions during the activity.",
                    images: [autoGroupInstructor]
                }
            ]
        },
        
    ],
    "Audio Discussion": [
        {
            title: 'Exersice Features',
            content: [
                {
                    text: "A group of students voice-discuss their answers to concept-related questions in an online setting.",
                    images: []
                }
            ]
        },
        {
            title: 'Student Side',
            content: [
                {
                    text: "Students engage in a voice discussion, addressing a set of questions related to a specific topic, with guidance on which questions to discuss and summaries of their conclusions. The exercise promotes critical thinking and collaborative learning.",
                    images: [audioStudent]
                }
            ]
        },
        {
            title: 'Instructor Side',
            content: [
                {
                    text: "Instructors receive live updates on the distribution of topics, students' answers, and engagement levels, helping them track the flow and quality of discussions. This exercise is designed for online classes where students connect via Zoom.",
                    images: [audioInstructor]
                }
            ]
        }
    ],
    "Helper/Helpee": [
        {
            title: 'Exersice Features',
            content: [
                {
                    text: "A peer instruction coding activity where students help each other on a voluntary basis after completing the problem.",
                    images: []
                },
            ]
        },
        
        {
            title: 'Student Side',
            content: [
                {
                    text: "After independently working on a coding problem, students who pass all unit tests can volunteer to help others. Those needing assistance can indicate their need, and the system automatically pairs them in a one-on-one chat, allowing multiple interactions.",
                    images: [helperStudent1, helperStudent2]
                }
            ]
        },
        {
            title: 'Instructor Side',
            content: [
                {
                    text: "Instructors can view real-time coding performance and track the content and engagement level of student discussions via a dashboard, gaining insights into peer interactions and the effectiveness of the help provided. This exercise supports Python, Blockly, Java, and MySQL.",
                    images: [helperInstructor]
                }
            ]
        },
    ],
    "VizMental": [
        {
            title: 'Exersice Features',
            content: [
                {
                    text: "Students work on coding problems with an AI learning assistant embedded with pedagogical guidance.",
                    images: []
                },
            ]
        },
        {
            title: 'Student Side',
            content: [
                {
                    text: "In this exercise, students work on coding tasks with the assistance of an AI learning partner that provides contextual support and pedagogical guidance. Students can seek help from the AI, which is designed to enhance their learning experience.",
                    images: [vizmentalStudent]
                }
            ]
        },
        {
            title: 'Instructor Side',
            content: [
                {
                    text: "Instructors can monitor coding performance, including errors made and questions asked to the AI, allowing them to assess the AI's effectiveness and the students' understanding. The exercise supports Python, Blockly, Java, and MySQL.",
                    images: [vizmentalInstructor]
                }
            ]
        }
    ],
};

const AboutTutorial = ({ exercise, onClose }) => {
    const [currentStep, setCurrentStep] = useState("step1");
    const [contentLoaded, setContentLoaded] = useState(false);
    const contentArray = contentArrays[exercise] || [];
    const navigate = useNavigate();


    useEffect(() => {
        if (contentLoaded) {
            const sections = document.querySelectorAll('.tutorial-step');

            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.2,
            };

            const observer = new IntersectionObserver((entries) => {
                const visibleEntries = entries.filter(entry => entry.isIntersecting);
                if (visibleEntries.length === 1) {
                    setCurrentStep(visibleEntries[0].target.id)
                }else if (visibleEntries.length > 0) {
                    visibleEntries.sort((a, b) => a.boundingClientRect.top - b.boundingClientRect.top);
                    setCurrentStep(visibleEntries[0].target.id);
                }
            }, options);

            sections.forEach(section => {
                observer.observe(section);
            });

            return () => {
                sections.forEach(section => {
                    observer.unobserve(section);
                });
            };
        }
    }, [contentLoaded]);

    useEffect(() => {
        if(!contentLoaded){
            setContentLoaded(true);
        }
    }, []);
    const handleLogin = () => {
        navigate("/login");
    }

    return (
        <Modal
            aria-labelledby="tutorial-modal-title"
            aria-describedby="tutorial-modal-description"
            open={true}
            onClose={onClose}
        >
            <Box className="tutorial-wrapper">
                <ModalClose
                    variant="outlined"
                    className="tutorial-close"
                />
                <Grid container>
                    <Grid item xs={2}>
                        <div className="sidebar">
                            <ul>
                                {contentArray.map((item, index) => (
                                    <li key={index}>
                                        <a
                                            href={`#step${index + 1}`}
                                            onClick = {() => setCurrentStep(`step${index + 1}`)}
                                            className = {currentStep === `step${index + 1}` ? 'active-step' : ''}
                                        >
                                            {item.title}
                                        </a>
                                    </li>
                                ))}
                                <li>
                                    <a className ="stickly-close-button" onClick={() => onClose()}> Close </a>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                    <div className="content">
                        {contentArray.map((step, index) => (
                            <div key={index} id={`step${index + 1}`} className="tutorial-step">
                                <Typography className="tutorial-modal-title" >
                                    {step.title}
                                </Typography>
                                {step.content.map((item, itemIndex) => (
                                    <div key={itemIndex}>
                                        <Typography className="tutorial-modal-description">
                                            {item.text}
                                        </Typography>
                                        <div >
                                            {item.images.map((src, imgIndex) => (
                                                <img className="tutorial-modal-image" key={imgIndex} src={src} alt={`${step.title} Image ${imgIndex + 1}`}  />
                                            ))}
                                        </div>
                                    </div>
                                ))}
                                {index < contentArray.length - 1 && <Divider />}
                            </div>
                        ))}
                        <div>
                            <Typography className="tutorial-modal-login" >
                                To try out this exercise, please
                                <Button Button
                                    className='login-button'
                                    onClick={handleLogin}>
                                    Login Here
                                </Button>
                            </Typography>
                            
                        </div>
                    </div>
                    </Grid>
                </Grid>

            </Box>
        </Modal>
    );
};

export default AboutTutorial;
