import Messages from './Messages'
import React, { useState, useEffect, useContext, useRef } from 'react'
import { SelectedGroupContext } from '../../context/SelectedGroupContext'
import { AuthContext } from '../../context/AuthContext'
import {
  getGroupByUser,
  getGroupsByUser,
  removeUserFromGroup,
  subscribeToGroupMessage,
} from '../../service/groupService'
import { getUsersByGroupId } from '../../service/userService'
import { MenuItem, Select, IconButton, Tooltip } from '@mui/material'
import { green, grey } from '@mui/material/colors'
import {
  sendMessageToAllGroup,
  sendMessageToGroup,
  sendDiscussionAnswer
} from '../../service/chatService'
import { SessionContext } from '../../context/SessionContext'
import { showToast } from '../commonUnit/Toast'
import { filterProfanity } from '../../tool/profanityFilter'
import { MessageContext } from '../../context/MessageContext'
import '../../css/audio/audioChat.scss'
import { ExitToApp, Mic, Stop } from '@mui/icons-material'
import { closeDeepgram, setupDeepgram } from '../../service/DeepgramService'
import {
  Backdrop,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@mui/material';

const AnswerSubmissionPopup = ({ isOpen, onClose, answer1, setAnswer1, answer2, setAnswer2, answer3, setAnswer3, submitAllAnswers }) => {

  const handleExit = () => {
    window.location.href = 'https://vizpi.org'; // Redirect to vizpi.org when the button is clicked
  };

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
    >
      <Paper sx={{ 
        padding: 3, 
        maxWidth: 500, 
        width: '90%', 
        maxHeight: '90vh', 
        overflow: 'auto',
        position: 'relative',
        paddingBottom: 7 // Add extra padding at the bottom for the button
      }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Time's Up! Submit Your Answers
        </Typography>
        <Typography variant="body1" paragraph>
          Please enter your answers below. It's okay to have different opinions than your group.
        </Typography>

        {/* Question and Options for Answer 1 */}
        <FormControl component="fieldset" margin="normal">
          <Typography component="legend">Question 1: I believe that the rapid advancements in generative AI outpace current copyright laws, leading to significant challenges in protecting intellectual property.</Typography>
          <RadioGroup
            row
            sx={{ mt: 1 }}
            value={answer1.selection}  // Bind selection to state
            onChange={(e) => setAnswer1({ ...answer1, selection: e.target.value })} // Update selection
          >
            <FormControlLabel value="agree" control={<Radio />} label="Agree" />
            <FormControlLabel value="disagree" control={<Radio />} label="Disagree" />
          </RadioGroup>
        </FormControl>
        
        <TextField
          fullWidth
          label="Provide reasoning for Answer 1"
          value={answer1.reasoning}  // Bind reasoning to state
          onChange={(e) => setAnswer1({ ...answer1, reasoning: e.target.value })} // Update reasoning
          variant="outlined"
          margin="normal"
        />
        
        {/* Question and Options for Answer 2 */}
        <FormControl component="fieldset" margin="normal">
          <Typography component="legend">Question 2: I think that the existing legal frameworks are insufficient to address the complexities of copyright issues arising from generative AI technology.</Typography>
          <RadioGroup
            row
            sx={{ mt: 1 }}
            value={answer2.selection}  // Bind selection to state
            onChange={(e) => setAnswer2({ ...answer2, selection: e.target.value })} // Update selection
          >
            <FormControlLabel value="agree" control={<Radio />} label="Agree" />
            <FormControlLabel value="disagree" control={<Radio />} label="Disagree" />
          </RadioGroup>
        </FormControl>

        <TextField
          fullWidth
          label="Provide reasoning for Answer 2"
          value={answer2.reasoning}  // Bind reasoning to state
          onChange={(e) => setAnswer2({ ...answer2, reasoning: e.target.value })} // Update reasoning
          variant="outlined"
          margin="normal"
        />
        
        {/* Question and Options for Answer 3 */}
        <FormControl component="fieldset" margin="normal">
          <Typography component="legend">Question 3: I believe that generative AI can enhance creativity without infringing on copyright, provided there are clear definitions of fair use.</Typography>
          <RadioGroup
            row
            sx={{ mt: 1 }}
            value={answer3.selection}  // Bind selection to state
            onChange={(e) => setAnswer3({ ...answer3, selection: e.target.value })} // Update selection
          >
            <FormControlLabel value="agree" control={<Radio />} label="Agree" />
            <FormControlLabel value="disagree" control={<Radio />} label="Disagree" />
          </RadioGroup>
        </FormControl>

        <TextField
          fullWidth
          label="Provide reasoning for Answer 3"
          value={answer3.reasoning}  // Bind reasoning to state
          onChange={(e) => setAnswer3({ ...answer3, reasoning: e.target.value })} // Update reasoning
          variant="outlined"
          margin="normal"
        />
        
        <Box sx={{ 
          position: 'absolute', 
          bottom: 16, 
          left: 23, 
          display: 'flex', 
          gap: 2
        }}>
          <Button onClick={handleExit} variant="outlined" color="secondary">
            Close and Exit
          </Button>
          <Button
            onClick={submitAllAnswers}
            variant="contained"
            color="primary"
            style={{ marginLeft: '140px' }} 
          >
            Submit Your Answers
          </Button>
        </Box>
      </Paper>
    </Backdrop>
  );
};


const AudioChat = () => {
  const [displayedMessages, setDisplayedMessages] = useState([])
  const { chatMessage, setChatMessage } = useContext(MessageContext)

  const { selectedGroup, setSelectedGroup, waiting, setWaiting } =
    useContext(SelectedGroupContext)
  const { currentUser } = useContext(AuthContext)
  const { session } = useContext(SessionContext)
  const [receiver, setReceiver] = useState('group')
  const [users, setUsers] = useState([])
  const [keystrokes, setKeystrokes] = useState([])
  const [isListening, setIsListening] = useState(false)
  const [isSpeaking, setIsSpeaking] = useState(false)
  const chatHistoryRef = useRef(null)
  const [showPopup, setShowPopup] = useState(true)
  const [answer1, setAnswer1] = useState("")
  const [answer2, setAnswer2] = useState("")
  const [answer3, setAnswer3] = useState("")

  const [discussionPoints, setDiscussionPoints] = useState([
    { title: "Problem Understanding", suggestedTime: 3, description: "What are the primary causes of copyright infringement concerns with generative AI?" },
    { title: "Technology's Contribution", suggestedTime: 3, description: "How do the technology's mechanisms and the use of copyrighted material contribute to this issue?" },
    { title: "Solutions and Strategies", suggestedTime: 3, description: "What strategies or legal frameworks could help address copyright challenges in the context of generative AI? How can creators and AI developers ensure fair use and protect intellectual property rights?" }
  ]);
  
  const [activeTimer, setActiveTimer] = useState(0);
  const [timeLeft, setTimeLeft] = useState(discussionPoints[0].suggestedTime * 60);
  const [totalTimeLeft, setTotalTimeLeft] = useState(10 * 60);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [expandedPoint, setExpandedPoint] = useState(null);

  const [showAnswerPopup, setShowAnswerPopup] = useState(false);

  useEffect(() => {
    setIsTimerRunning(true)
    let timer;
    if (isTimerRunning && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prev => Math.max(prev - 1, 0));
        setTotalTimeLeft(prev => {
          const newTotalTime = Math.max(prev - 1, 0);
          if (newTotalTime === 0) {
            setShowAnswerPopup(true);
          }
          return newTotalTime;
        });
      }, 1000);
    } else if (timeLeft === 0 && activeTimer < discussionPoints.length - 1) {
      setActiveTimer(prev => prev + 1);
      setTimeLeft(discussionPoints[activeTimer + 1].suggestedTime * 60);
    }
    return () => clearInterval(timer);
  }, [isTimerRunning, timeLeft, activeTimer, discussionPoints]);
  
  useEffect(() => {
    localStorage.setItem('totalTimeLeft', totalTimeLeft.toString());
  }, [totalTimeLeft]);

  const toggleTimer = (index) => {
    if (index === activeTimer) {
      setIsTimerRunning(!isTimerRunning);
    } else if (timeLeft > 0) {
      setActiveTimer(index);
      setTimeLeft(discussionPoints[index].suggestedTime * 60);
      setIsTimerRunning(true);
    }
  };

  const toggleExpandPoint = (index) => {
    setExpandedPoint(expandedPoint === index ? null : index);
  };

  useEffect(() => {
    if (!session.enable_chat) {
      setIsListening(false)
      closeDeepgram()
    }
  }, [session])

  useEffect(() => {
    const fetchGroup = async () => {
      if (!selectedGroup && currentUser && session.grouped) {
        if (currentUser.role === 3) {
          const group = await getGroupByUser(currentUser.id, session.id)
          console.log('group', group)
          setSelectedGroup(group)
        }
      }
    }
    fetchGroup()
  }, [currentUser, selectedGroup, session.grouped])

  useEffect(() => {
    // console.log(session)
    if (!session.grouped) {
      //setSelectedGroup(null)
    }
    let me = session?.identity_list?.find(
      (element) => element.id === currentUser.id
    )
    if (me === undefined && waiting) {
      setWaiting(false)
    } else if (me != null && !waiting) {
      setWaiting(true)
    }
  }, [session])

  useEffect(() => {
    let speakingInterval
    if (isListening) {
      speakingInterval = setInterval(() => {
        setIsSpeaking((prev) => !prev)
      }, 1000)
    } else {
      clearInterval(speakingInterval)
      setIsSpeaking(false)
    }
    return () => clearInterval(speakingInterval)
  }, [isListening])

  useEffect(() => {
    if (!selectedGroup) {
      return
    }
    setUsers([])

    if (!selectedGroup.user_ids) {
      return
    }
    const fetchUsers = async () => {
      getUsersByGroupId(selectedGroup.id)
        .then((users) => {
          setUsers(users)
        })
        .catch((err) => {
          console.error(err)
        })
    }
    fetchUsers()

    const unsubscribe = subscribeToGroupMessage(
      currentUser.role === 3 ? currentUser.id : -1,
      selectedGroup.id,
      setDisplayedMessages
    )
    // console.log('subscribed to group', selectedGroup.id)
    return () => {
      unsubscribe()
    }
  }, [selectedGroup])

  useEffect(() => {
    // console.log('displayedMessages', displayedMessages)
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight
    }
  }, [displayedMessages])

  const handleRecording = async () => {
    if (!isListening) {
      setupDeepgram((message) =>
        sendMessageToGroup(
          filterProfanity(message),
          currentUser,
          selectedGroup.id,
          keystrokes
        )
      )
      setIsListening(true)
    } else {
      closeDeepgram()
      setIsListening(false)
    }
  }

  const handleUserChange = (e) => {
    setReceiver(e.target.value)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      sendMessage(e)
    }
  }

  const answer1Submit = async (e) => {
    if (e != undefined) {
      e.preventDefault()
    }
    if (session?.group_round == 2 && selectedGroup?.group_round !== 2) {
      return
    }

    let sentMessage = await sendDiscussionAnswer(
      filterProfanity(answer1),
      currentUser,
      selectedGroup.id,
      1,
      keystrokes
    )

    setKeystrokes([])
      if (sentMessage !== undefined) {
        setChatMessage('')
      }

    setAnswer1("")
  }

  const answer2Submit = async (e) => {
    if (e != undefined) {
      e.preventDefault()
    }
    if (session?.group_round == 2 && selectedGroup?.group_round !== 2) {
      return
    }

    let sentMessage = await sendDiscussionAnswer(
      filterProfanity(answer2),
      currentUser,
      selectedGroup.id,
      2,
      keystrokes
    )

    setKeystrokes([])
      if (sentMessage !== undefined) {
        setChatMessage('')
      }
    setAnswer2("")
  }

  const answer3Submit = async (e) => {
    if (e != undefined) {
      e.preventDefault()
    }
    if (session?.group_round == 2 && selectedGroup?.group_round !== 2) {
      return
    }

    let sentMessage = await sendDiscussionAnswer(
      filterProfanity(answer3),
      currentUser,
      selectedGroup.id,
      3,
      keystrokes
    )

    setKeystrokes([])
      if (sentMessage !== undefined) {
        setChatMessage('')
      }
    setAnswer3("")
  }

  const sendMessage = async (e) => {
    if (e != undefined) {
      e.preventDefault()
    }
    if (session?.group_round == 2 && selectedGroup?.group_round !== 2) {
      return
    }

    if (true) {
      let sentMessage
      if (receiver !== 'class') {
        sentMessage = await sendMessageToGroup(
          filterProfanity(chatMessage),
          currentUser,
          selectedGroup.id,
          keystrokes
        )
      } else {
        sentMessage = await sendMessageToAllGroup(
          filterProfanity(chatMessage),
          currentUser,
          keystrokes
        )
      }
      setKeystrokes([])
      if (sentMessage !== undefined) {
        setChatMessage('')
      }
    } else {
      showToast('Chat is disabled', 'warning')
    }
  }

  const handleAcceptRecording = () => {
    setShowPopup(false)
    handleRecording()
  }

  const handleRemoveUserFromGroup = async () => {
    if (selectedGroup) {
      await removeUserFromGroup(selectedGroup.id, currentUser.id)
      setSelectedGroup(null)
    }
  }

  if (!selectedGroup) {
    if (currentUser.role === 3) {
      if (session.type === 'Helper/Helpee') {
        return (
          <div className="chat">
            Use the 'Ask for Help' or 'Offer Help to Others' button to help and
            chat with other students
          </div>
        )
      }
    }
  }

  const submitAllAnswers = async () => {
  try {
    const answers = [
      {
        content: `question1-tf-${answer1.selection}`, // True/False answer with tag
        questionNumber: 1
      },
      {
        content: `question1-open-${answer1.reasoning}`, // Open-ended reasoning with tag
        questionNumber: 1
      },
      {
        content: `question2-tf-${answer2.selection}`, // True/False answer for question 2
        questionNumber: 2
      },
      {
        content: `question2-open-${answer2.reasoning}`, // Open-ended reasoning for question 2
        questionNumber: 2
      },
      {
        content: `question3-tf-${answer3.selection}`, // True/False answer for question 3
        questionNumber: 3
      },
      {
        content: `question3-open-${answer3.reasoning}`, // Open-ended reasoning for question 3
        questionNumber: 3
      }
    ];

    // Loop through the answers and send them to the database
    for (const answer of answers) {
      await sendDiscussionAnswer(
        filterProfanity(answer.content),
        currentUser,
        selectedGroup.id,
        answer.questionNumber,
        keystrokes
      );
    }

    setShowAnswerPopup(false);
    setAnswer1({ selection: "", reasoning: "" });
    setAnswer2({ selection: "", reasoning: "" });
    setAnswer3({ selection: "", reasoning: "" });
    showToast('All answers submitted successfully', 'success');
  } catch (error) {
    console.error('Error submitting answers:', error);
    showToast('Failed to submit answers. Please try again.', 'error');
  }
};


  return (
    <div className="central-panel">
      <div className="audio-chat-container">
        <div className="chat">
          <div className="audio-list"></div>
          <div className="group-name">
            {selectedGroup ? selectedGroup.name : 'No Group Selected'}
            <Tooltip title="Exit current group">
              <IconButton
                color="primary"
                onClick={handleRemoveUserFromGroup}
                className="leave-group-button">
                <ExitToApp />
              </IconButton>
            </Tooltip>
          </div>
          <div className="chat-history" ref={chatHistoryRef}>
            <Messages messages={displayedMessages} />
          </div>
          <form className="message-form" onSubmit={sendMessage}>
            <IconButton
              onClick={handleRecording}
              style={{
                width: 48,
                height: 48,
                borderRadius: '50%',
                border: `6px solid ${
                  isListening
                    ? isSpeaking
                      ? green[500]
                      : grey[500]
                    : 'transparent'
                }`,
                transition: 'border-color 0.3s ease',
                padding: 0,
              }}
              color="primary">
              {isListening ? <Stop /> : <Mic />}
            </IconButton>
            <textarea
              className="message-input"
              value={chatMessage}
              onChange={(e) => setChatMessage(e.target.value)}
              placeholder={
                session.enable_chat
                  ? 'Use Shift + Enter for new line'
                  : 'Chat is disabled'
              }
              onKeyDown={handleKeyPress}
            />
            <button type="submit" className="send-button">
              Send
            </button>
          </form>
        </div>
 
        {!session?.type.startsWith("Blockly") && (
          <>
            <div className="timer-panel">
              <h3>Discussion Guide</h3>
              <p className="scenario">
                Scenario: Generative AI systems, like those used to create art, music, and text, have raised
                significant concerns about copyright infringement. In this exercise, we will explore the
                relationship between generative AI and copyright issues. Students will discuss the
                underlying problem, the causes of these copyright challenges, and brainstorm possible
                solutions to navigate the ethical and legal complexities in this area.
              </p>
              <div className="dropdowns-container">
                <div className="dropdowns">
                  {discussionPoints.map((point, index) => (
                    <div key={index} className={`timer-item ${activeTimer === index ? 'active' : ''}`} onClick={() => toggleTimer(index)}>
                      <div className="timer-header">
                        <span className="timer-title">{point.title}</span>
                        <span className="timer-time">
                          {activeTimer === index ? `${Math.floor(timeLeft / 60)}:${(timeLeft % 60).toString().padStart(2, '0')}` : '3:00'}
                        </span>
                      </div>
                      {expandedPoint === index && (
                        <div className="timer-description">{point.description}</div>
                      )}
                      <button className="expand-button" onClick={(e) => { e.stopPropagation(); toggleExpandPoint(index); }}>
                        {expandedPoint === index ? '▲' : '▼'}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="total-time">
                <span className="timer-title">Total Time Left:</span>
                <span className="timer-time">
                  {totalTimeLeft > 0 
                    ? `${Math.floor(totalTimeLeft / 60)}:${(totalTimeLeft % 60).toString().padStart(2, '0')}`
                    : "0:00"}
                </span>
              </div>
            </div>
            <AnswerSubmissionPopup 
              isOpen={showAnswerPopup}
              onClose={() => setShowAnswerPopup(false)}
              answer1={answer1}
              setAnswer1={setAnswer1}
              answer2={answer2}
              setAnswer2={setAnswer2}
              answer3={answer3}
              setAnswer3={setAnswer3}
              submitAllAnswers={submitAllAnswers}  
            />
          </>
        )}
        
      </div>
    </div>
  );
}

export default AudioChat