export const generateVariableQuestions = (selectedText) => {
    const match = selectedText.match(/(\b\w+\b)\s*=/);
    const variableName = match ? match[1] : 'this variable';
  
    return [
      `What value should be assigned to "${variableName}"?`, 
      `Is "${variableName}" the correct type for the assigned value?`,  
      `Where should "${variableName}" be defined?`,
    ];
  };
  
export const questionPatterns = [
    {
      pattern: /def\s+\w+\s*\(/, 
      questions: [
        "What parameters should this function take?",
        "Am I defining this function correctly?",
        "What should this function return?",
      ],
    },
    {
      pattern: /for\s+\w+\s+in\s+/, 
      questions: [
        "What should the for loop iterate over?",
        "Am I defining the range/looping condition of the for loop correctly?",
        "Are there any edge cases where my for loop might fail?"
      ],
    },
    {
      pattern: /if\s+/, 
      questions: [
        "What condition should be checked in this if statement?",
        "What should happen if the if statement's condition is true?",
        "Is the logic in this if statement's condition correct?"
      ],
    },
    {
      pattern: /while\s+/, 
      questions: [
        "What should the while loop condition check?",
        "What should happen in each iteration of the while loop?",
        "What should be the initial condition of the while loop?",
      ],
    },
    {
      pattern: /(\b\w+\b)\s*=\s*[^=]/, 
      questions: [], 
      generateQuestions: generateVariableQuestions,
    },
    {
        pattern: /return\s+/, 
        questions: [
          "Am I returning the correct value?",
          "What should be returned from this function?",
        "Is the return statement in the correct place?",
        ],
      },
  ];

  export const defaultQuestions = [
    "What is wrong with my code?",
    "Why is this code giving me unexpected results?",
    "Is this the correct way to approach this task?",
  ];

  export const generateErrorQuestions = (errorMessage) => {
    let generatedQuestions = [];

    const errorPatterns = [
      {  
          pattern: /NameError:/,
          generateQuestions: () => [
              `Why is there a name error in this line?`,
              `Did I forget to declare or import something?`,
              `Where should this name be defined or imported?`
          ]
      },
      {
          pattern: /SyntaxError:/,
          generateQuestions: () => [
              "What is wrong with the syntax in this line?",
              "Is there a missing parenthesis or comma?",
              "Why is this line not being parsed correctly?"
          ]
      },
      {
          pattern: /TypeError:/,
          generateQuestions: () => [
              `Why am I trying to call something as a function?`,
              `Is this supposed to be a function?`,
              `Did I accidentally overwrite a function with a non-function value?`
          ]
      },
      {
          pattern: /IndentationError:/,
          generateQuestions: () => [
              "Why is there an indentation error here?",
              "Is there a mix of spaces and tabs?",
              "Did I correctly align all code blocks?"
          ]
      },
      {
          pattern: /AttributeError:/,
          generateQuestions: () => [
              `Why doesn't an object have a certain attribute?`,
              `Did I misspell the attribute or forget to define it?`,
              `Is this the correct attribute for the object?`
          ]
      },
      {
          pattern: /IndexError:/,
          generateQuestions: () => [
              "Why am I getting a list index out of range error?",
              "Is the index I am trying to access within the valid range?",
              "Did I initialize the list with the correct length?"
          ]
      },
      {
          pattern: /KeyError:/,
          generateQuestions: () => [
              `Why is a certain key not found in the dictionary?`,
              `Did I misspell the key or forget to add it to the dictionary?`,
              `Is this the correct key to use here?`
          ]
      }
  ];
  

    // Iterate through the error patterns to match and generate questions
    errorPatterns.forEach(({ pattern, generateQuestions }) => {
        const match = errorMessage.match(pattern);
        if (match) {
            generatedQuestions = generatedQuestions.concat(generateQuestions(match));
        }
    });

    // Return generated questions or a default set if no patterns matched
    return generatedQuestions.length > 0
        ? generatedQuestions
        : ["What does this error mean?", "How can I fix this error?"];
};
