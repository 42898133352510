import React from 'react';
import { Box, Button, Typography, Modal, Stack, TextField, Slider, FormControl, Radio, FormControlLabel, RadioGroup, Grid } from '@mui/material';
import { AuthContext } from '../../context/AuthContext';
import { SessionContext } from '../../context/SessionContext';
import { addReview, createReview } from '../../service/reviewService';
import { SelectedGroupContext } from '../../context/SelectedGroupContext';
import { VizmentalReviewQuestions, SphereReviewQuestions } from './data/ReviewQuestions';
import { Checkbox, FormGroup } from '@mui/material';
import { getLatestNotification } from '../../service/notificationService';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  maxHeight: '50vh',
  overflowY: 'auto',
  padding: '30px',
};

export default function ReviewWindow({ sessionType }) {
  const [open, setOpen] = React.useState(true);
  const [everSubmit, setEverSubmit] = React.useState(false);
  const [commentText, setCommentText] = React.useState('');
  const [rating, setRating] = React.useState(null);
  const { currentUser } = React.useContext(AuthContext);
  const { session } = React.useContext(SessionContext);
  const { selectedGroup } = React.useContext(SelectedGroupContext);
  const [responses, setResponses] = React.useState({});
  const [reviewQuestions, setReviewQuestions] = React.useState(sessionType === 'Vizmental' ? VizmentalReviewQuestions : SphereReviewQuestions);
  const [error, setError] = React.useState('');  // State for managing error message
  const [notificated, setNotificated] = React.useState(false);
  const handleModalOpen = () => setOpen(true);

  
  const checkNotification = async () => {
    const notification = await getLatestNotification(session.id, currentUser.id);
    if (notification && notification.viewed) {
      setNotificated(true);
    }
  }

  React.useEffect(() => {
    if(!notificated) {
      const interval = setInterval(() => {
        checkNotification()
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [session, currentUser]);

  const handleCheckboxChange = (description, value, checked) => {
    let currentValues = responses[description] || [];
    if (checked) {
      currentValues = [...currentValues, value];
    } else {
      currentValues = currentValues.filter((v) => v !== value);
    }
    setResponses({
      ...responses,
      [description]: currentValues,
    });
  };

  const handleInputChange = (description, value) => {
    setResponses({
      ...responses,
      [description]: value,
    });
  };

  const handleSliderChange = (description, event, value) => {
    handleInputChange(description, value);
  };

  const validateForm = () => {
    for (let question of reviewQuestions) {
      if (question.inputType !== 'textfield') {
        const response = responses[question.description];
        if (
          response === undefined ||
          response === null ||
          response === '' ||
          (Array.isArray(response) && response.length === 0)
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      setError('Please fill in all required fields.');
      return;
    }
    setOpen(false);
    try {
      let review = createReview(
        commentText,
        responses,
        selectedGroup?.id,
        rating,
        session.id,
        currentUser.id
      );
      await addReview(review);
      setEverSubmit(true);
    } catch (error) {
      console.error('Error updating rating: ', error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      {!everSubmit && (
        <Box sx={{ width: '100%' }}>
          <Button
            onClick={handleModalOpen}
            variant="outlined"
            sx={{ height: '20px' }}
          >
            Rating Discussion
          </Button>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                We need your feedback!
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#1976d2",fontWeight:"bold" }}>
                Please answer the following survey questions:
              </Typography>
              <form>
                {reviewQuestions.map((question, index) => {
                  if (question.inputType === "radio") {
                    return (
                      <Box key={index}>
                        <Typography sx={{ mt: 2 }}>{question.label}</Typography>
                        <FormControl>
                          <RadioGroup
                            aria-label={question.label}
                            value={responses[question.description] || ''}
                            onChange={(event) => handleInputChange(question.description, event.target.value)}
                          >
                            {question.options.map((option, optionIndex) => (
                              <FormControlLabel
                                key={optionIndex}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    );
                  } else if (question.inputType === 'textfield') {
                    return (
                      <Box key={index}>
                        <Typography sx={{ mt: 2 }}>{question.label}</Typography>
                        <TextField
                          label="Additional Feedback"
                          multiline
                          rows={4}
                          fullWidth
                          margin="normal"
                          value={responses[question.description] || ''}
                          onChange={(event) =>
                            handleInputChange(question.description, event.target.value)
                          }
                          placeholder="Details/Feedback/Suggestions/anything else you want to say"
                        />
                      </Box>
                    );
                  } else if  (question.inputType === 'scale') {
                    return (
                      <Box key={index}>
                        <Typography sx={{ mt: 2 }}>{question.label}</Typography>
                        <RadioGroup
                          row
                          value={responses[question.description] || ''}
                          onChange={(event) =>
                            handleSliderChange(question.description, event, event.target.value)
                          }
                          sx={{ justifyContent: 'center', flexWrap: 'nowrap' }}  
                        >
                          {[1, 2, 3, 4, 5].map((value) => (
                            <Grid item key={value} sx={{ textAlign: 'center'}}>
                              <FormControlLabel
                                value={String(value)}
                                control={<Radio sx={{
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 16, // Adjust size if needed
                                  },
                                }} />}
                                label={String(value)}
                                labelPlacement="bottom"  // Places label below the radio button
                              />
                            </Grid>
                          ))}
                        </RadioGroup>
                      </Box>
                    );
                  } else if (question.inputType === 'multiple_choice') {
                    if (question.description === 'feedback_impact' && notificated===false) {
                      return null;
                    }
                    return (
                      <Box key={index}>
                        <Typography sx={{ mt: 2 }}>{question.label}</Typography>
                        <FormControl component="fieldset">
                          <FormGroup>
                            {question.options.map((option, optionIndex) => (
                              <FormControlLabel
                                key={optionIndex}
                                control={
                                  <Checkbox
                                    checked={
                                      responses[question.description]?.includes(option.value) || false
                                    }
                                    onChange={(event) =>
                                      handleCheckboxChange(
                                        question.description,
                                        option.value,
                                        event.target.checked
                                      )
                                    }
                                  />
                                }
                                label={option.label}
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </Box>
                    );
                  }
                })}
              </form>
              {error && (
                <Typography color="error" sx={{ mt: 2 }}>
                  {error}
                </Typography>
              )}
              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
                <Button onClick={handleClose} variant="outlined">
                  Later
                </Button>
              </Stack>
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
}
