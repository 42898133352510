import Filter from 'bad-words'


export const filterProfanity = (userInput) => {
  if (/[a-zA-Z]/g.test(userInput) === false) {
    return userInput
  }
  const filter = new Filter()
  let cleanedText = ''
  if (/^\*+$/.test(userInput)) {
    cleanedText = userInput
  } else {
    cleanedText = filter.clean(userInput)
  }
  return cleanedText
}
