import defaultAvatar1 from "../icon/defaultAvatars/user.png";
import defaultAvatar2 from "../icon/defaultAvatars/user1.png";
import defaultAvatar3 from "../icon/defaultAvatars/user2.png";
import defaultAvatar4 from "../icon/defaultAvatars/user3.png";
import defaultAvatar5 from "../icon/defaultAvatars/user4.png";
import defaultAvatar6 from "../icon/defaultAvatars/user5.png";

const defaultAvatars = [
  defaultAvatar1,
  defaultAvatar2,
  defaultAvatar3,
  defaultAvatar4,
  defaultAvatar5,
  defaultAvatar6
];

const avatarAssignments = new Map();
let nextAvatarIndex = 0;

export const getAvatar = (url, userId) => {
  if (url && url !== "") return url;
  
  if (avatarAssignments.has(userId)) {
    return avatarAssignments.get(userId);
  }

  const newAvatar = defaultAvatars[nextAvatarIndex];
  avatarAssignments.set(userId, newAvatar);
  nextAvatarIndex = (nextAvatarIndex + 1) % defaultAvatars.length;

  return newAvatar;
};

export const resetAvatarAssignments = () => {
  avatarAssignments.clear();
  nextAvatarIndex = 0;
};