import { socket } from "../tool/socketIO"

const axios = require("axios")
const messageUrl = process.env.REACT_APP_HOST_API + "/chat"
const discussionAnswerUrl = process.env.REACT_APP_HOST_API + "/discussionAnswer"
const gptEvaluationUrl = process.env.REACT_APP_HOST_API + "/gpt/evaluateDiscussionAnswer";


// -1 is message to group
// -2 is message to class
// -3 is private message to instructor
const REVEIVER_TYPE = {
	GROUP: -1,
	CLASS: -1,
	INSTRUCTOR: -1,
	AI: -4
}

export const sendMessageToGroup = async (content, user, groupId, keystrokes) => {
	if (!content || content === "" || !user || !user.session_id || !groupId)
		return null
	const messageData = {
		group_id: groupId,
		sender_id: user.id,
		session_id: user.session_id,
		code_id: user.code_id,
		content: content,
		recipient_id: REVEIVER_TYPE.GROUP,
		keystrokes: keystrokes
	}
	//console.log("message sent",messageData)
	return axios
		.post(messageUrl, messageData)
		.then((response) => {
			return response.data.message
		})
		.catch((error) => {
			console.error(error)
		})
}

export const sendDiscussionAnswer = async (content, user, groupId, questionNumber, keystrokes) => {
	if (!content || content === "" || !user || !user.session_id || !groupId)
		return null
	const answerData = {
		group_id: groupId,
		sender_id: user.id,
		session_id: user.session_id,
		code_id: user.code_id,
		content: content,
		recipient_id: REVEIVER_TYPE.GROUP,
		questionNumber: questionNumber,
		keystrokes: keystrokes
	}

	return axios
		.post(discussionAnswerUrl, answerData)
		.then((response) => {
			return response.data.answer
		})
		.catch((error) => {
			console.error(error)
		})
}

export const getAllAnswersBySessionId = async (sessionId) => {
	console.log(`Fetching answers for session ${sessionId}`);
	try {
	  const response = await axios.get(`${discussionAnswerUrl}/session/${sessionId}/answers`);
	  console.log('Response received:', response.data);
	  return response.data.answers;
	} catch (error) {
	  console.error('Error fetching all answers:', error);
	  if (error.response) {
		console.error('Response data:', error.response.data);
		console.error('Response status:', error.response.status);
	  }
	  throw error;
	}
  };

export const submitAndEvaluateDiscussionAnswer = async (content, user, groupId, questionNumber, keystrokes) => {
	if (!content || content === "" || !user || !user.session_id || !groupId) {
	  return null;
	}
  
	const answerData = {
	  group_id: groupId,
	  sender_id: user.id,
	  session_id: user.session_id,
	  code_id: user.code_id,
	  content: content,
	  recipient_id: REVEIVER_TYPE.GROUP,
	  questionNumber: questionNumber,
	  keystrokes: keystrokes
	};
  
	try {
	  // First, submit the answer
	  const answerResponse = await axios.post(discussionAnswerUrl, answerData);
  
	  // Then, send the answer for GPT evaluation
	  const evaluationResponse = await axios.post(gptEvaluationUrl, {
		answer: content,
		questionNumber: questionNumber,
		sessionId: user.session_id
	  });
  
	  // Return both the submitted answer and its evaluation
	  return {
		submittedAnswer: answerResponse.data.answer,
		evaluation: evaluationResponse.data.evaluation
	  };
	} catch (error) {
	  console.error('Error in submitting and evaluating discussion answer:', error);
	  throw error;
	}
  };

export const sendMessageToAllGroup = async (content, user, keystrokes) => {
	if (!content || content === "" || !user || !user.session_id) return null

	const messageData = {
		group_id: REVEIVER_TYPE.CLASS,
		sender_id: user.id,
		session_id: user.session_id,
		content: content,
		code_id: user.code_id,
		// recipient_id == -2 when this message send to class (all group)
		recipient_id: REVEIVER_TYPE.CLASS,
		keystrokes: keystrokes
	}

	return axios
		.post(messageUrl, messageData)
		.then((response) => {
			return response.data.message
		})
		.catch((error) => {
			console.error(error)
		})
}

export const getMessagesByGroup = async (groupId) => {
	try {
		const response = await axios.get(messageUrl + "/group/" + groupId)
		return response.data.messages
	} catch (error) {
		//console.log(error)
	}
}

export const getMessagesBySession = async (sessionId) => {
	try {
		const response = await axios.get(messageUrl + "/session/" + sessionId)
		return response.data.messages
	} catch (error) {
		//console.log(error)
	}
}

export const sendMessageToAI = async (content, user, session_id, keystrokes) => {
	if (!content || content === "" || !user || !session_id) return null
	// console.log("qt",question_type)
	console.log("sending message to AI: content",content, "user", user, "session_id", session_id)

	const messageData = {
		group_id: REVEIVER_TYPE.AI,
		sender_id: user.id,
		session_id: session_id,
		content: content,
		code_id: user.code_id,
		// recipient_id == -2 when this message send to class (all group)
		recipient_id: REVEIVER_TYPE.AI,
		keystrokes: keystrokes
	}

	return axios
		.post(messageUrl, messageData)
		.then((response) => {
			return response.data.message
		})
		.catch((error) => {
			console.error(error)
		})
}

export const sendAIResponseToUser = async (content, user, session_id, keystrokes) => {
	console.log("sendAIResponseToUser", content, user, keystrokes)
	if (!content || content === "" || !user || !session_id){
		//console.log("content", content, "user", user, "session_id", user.session_id)
	return null
	}

	const messageData = {
		group_id: REVEIVER_TYPE.AI,
		sender_id: REVEIVER_TYPE.AI,
		session_id: session_id,
		content: content,
		code_id: user.code_id,
		recipient_id: user.id,
		keystrokes: keystrokes
	}

	return axios
		.post(messageUrl, messageData)
		.then((response) => {
			return response.data.message
		})
		.catch((error) => {
			console.error(error)
		})
}

export const getMessagesBetweenUserAndAI = async (userId, sessionId) => {
	if(!userId || !sessionId) return null
	try {
		const response = await axios.get(messageUrl + "/ai/" + userId + "/" + sessionId)
		return response.data.messages
	} catch (error) {
		//console.log(error)
	}
}

export const subscribeToAIMessage = (userId,sessionId, updateMessages) => {
    const fetchInitialMessages = async () => {
        const messages = await getMessagesBetweenUserAndAI(userId, sessionId);
        updateMessages(messages);
    };

    fetchInitialMessages();

    const updateMessageHandler = (message) => {
        updateMessages((prevMessages) => {
            let newMessages = [...prevMessages];
            
            newMessages.push(message.data);
            
            return newMessages;
        });
    };

    socket.emit('subscribe ai', userId);
    socket.on('update ai message', updateMessageHandler);

    return () => {
        socket.off('update ai message', updateMessageHandler);
        socket.emit('unsubscribe ai', userId);
    };
};

export const subscribeToAIMessageInSession = (sessionId, updateMessages) => {
    const fetchInitialMessages = async () => {
        const messages = await getMessagesBySession(sessionId);
        updateMessages(messages);
    };

    fetchInitialMessages();

    const updateMessageHandler = (message) => {
        updateMessages((prevMessages) => {
            let newMessages = [...prevMessages];
            
            newMessages.push(message.data);
            
            return newMessages;
        });
    };

    socket.emit('subscribe ai', sessionId);
    socket.on('update ai message', updateMessageHandler);

    return () => {
        socket.off('update ai message', updateMessageHandler);
        socket.emit('unsubscribe ai', sessionId);
    };
};