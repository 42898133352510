const axios = require("axios");
const instructorUrl = process.env.REACT_APP_HOST_API + "/instructors"; 

export const getInstructorBySessionId = async (sessionId) => {
	if (!sessionId) return null;

	return axios
		.get(instructorUrl + "/session/" + sessionId)
		.then((response) => {
            return response.data.instructor
		})
		.catch((error) => {
			console.error(error);
		});
};
export const updateInstructor = async (instructor) => {
	if (!instructor) return null
	//console.log("setSessionInUser", userId, sessionId)
	let res = false
	await axios
		.put(instructorUrl + "/" + instructor.id, { instructor })
		.then((response) => {
			res = true
		})
		.catch((error) => {
			console.error(error)
		})
	return res
}
export const getInstructorById = async (id) => {
	if (!id) return null

	let res = null
	try {
		const response = await axios.get(instructorUrl + "/" + id)
		res = response.data.instructor
		return res
	} catch (error) {
		console.error(error)
		throw error
	}
}