import React, { useState, useEffect } from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Grid from '@mui/joy/Grid';
import Box from '@mui/joy/Box';
import { styled } from '@mui/joy/styles';
import { keyframes } from '@mui/system';

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
`;

const AvatarBox = styled(Box)(({ theme }) => ({
  width: 100,
  height: 100,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  overflow: 'hidden',
  border: `3px solid ${theme.vars.palette.background.body}`,
  boxShadow: `0 0 0 3px ${theme.vars.palette.primary.softBg}`,
  margin: 'auto',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: `0 0 0 4px ${theme.vars.palette.primary.main}`,
  },
}));

const AvatarImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const AvatarSelectionModal = ({ open, onClose, onSelect, currentAvatar }) => {
  const [avatars, setAvatars] = useState([]);

  useEffect(() => {
    const avatarList = [
      'avatarM1.jpg', 'avatarF1.jpg', 'avatarM2.jpg', 'avatarF2.jpg', 'avatarM3.jpg',
      'avatarF3.jpg', 'avatarM4.jpg', 'avatarF4.jpg', 'avatarM5.jpg', 'avatarF5.jpg',
    ];
    setAvatars(avatarList);
  }, []);

  const getInitials = (path) => {
    const name = path.split('/').pop().split('.')[0];
    return name.replace(/[^a-zA-Z]/g, '').substring(0, 2).toUpperCase();
  };

  const getImagePath = (filename) => {
    try {
      return require(`../../icon/avatars/${filename}`);
    } catch (error) {
      console.error(`Failed to load image: ${filename}`, error);
      return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        aria-labelledby="avatar-select-modal"
        layout="center"
        size="lg"
        variant="outlined"
        sx={{
          maxWidth: 600,
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0,0,0,0.2)',
            borderRadius: '50%',
            bgcolor: 'background.body',
          }}
        />
        <Typography
          level="h4"
          sx={{
            mb: 2,
            textAlign: 'center',
            color: 'primary.main',
            fontWeight: 'bold',
          }}
        >
          Choose Your Avatar
        </Typography>
        <Box sx={{ width: '100%', p: 2 }}>
          <Grid 
            container 
            spacing={3} 
            justifyContent="center" 
            alignItems="center"
          >
            {avatars.map((avatar, index) => {
              const imagePath = getImagePath(avatar);
              return (
                <Grid key={index} item xs={6} sm={4} md={3}>
                  <AvatarBox
                    onClick={() => onSelect(avatar)}
                    sx={(theme) => ({
                      ...(avatar === currentAvatar && {
                        border: `3px solid ${theme.vars.palette.primary.main}`,
                        animation: `${pulse} 2s infinite`,
                      }),
                    })}
                  >
                    <AvatarImage
                      src={imagePath || ''}
                      alt={`Avatar ${index + 1}`}
                      onError={(e) => {
                        console.error(`Failed to load image: ${avatar}`);
                        e.target.onerror = null;
                        e.target.src = `data:image/svg+xml,${encodeURIComponent(`
                          <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <rect width="100" height="100" fill="#f0f0f0" />
                            <text x="50%" y="50%" font-size="24" fill="#333" text-anchor="middle" dy=".3em">${getInitials(avatar)}</text>
                          </svg>
                        `)}`;
                      }}
                    />
                  </AvatarBox>
                </Grid>
              );
          })}
          </Grid>
        </Box>
        <Typography level="body2" sx={{ mt: 2, textAlign: 'center', color: 'text.secondary' }}>
          Click on an avatar to select it as your profile picture.
        </Typography>
      </ModalDialog>
    </Modal>
  );
};

export default AvatarSelectionModal;