import React, { useState, useContext, useEffect } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import { indentUnit } from '@codemirror/language';
import '../../css/exampleCode.scss';
import { Typography, Switch, Box, Button } from '@mui/material';
import { SelectedCodeContext } from '../../context/SelectedCodeContext';
import { AuthContext } from '../../context/AuthContext';
import { toast } from 'react-toastify'; 
import { loadPyodide } from 'pyodide';
import { SessionContext } from '../../context/SessionContext';
import { TestResultContext } from '../../context/TestResultContext';
import { getPassrateByResult } from '../../tool/progressUnit'
import axios from 'axios'
import { getCodeByUserId } from '../../service/codeService'



const ExampleCode = ({}) => {
  const [exampleCode, setExampleCode] = useState('');
  const {session} =useContext(SessionContext)
  const { setTestResult } = useContext(TestResultContext)
  const [showEditor, setShowEditor] = useState(false);
  const { setSelectedCode } = useContext(SelectedCodeContext);
  const { currentUser } = useContext(AuthContext);
  const [isRunning, setIsRunning] = useState(false);
  const [output, setOutput] = useState('');
  const [error, setError] = useState('');

  const codeUrl = process.env.REACT_APP_HOST_API + '/codes'

  // useEffect(() => {
  //   if(!currentUser|| !session) return;
  //   const getInstructorCode = async () => {
  //     const id = (-currentUser.id).toString();
  //     console.log("userid:",(-currentUser.id).toString());
  //     const instructorCode = await getCodeByUserId(id);
  //     console.log("instructorCode:", instructorCode);
  //     // setExampleCode(instructorCode);
  //   }
  //   getInstructorCode();
  // }, [session,currentUser]);


  const handleCodeChange = (value) => {
    setExampleCode(value);
  };

  const handleToggleChange = (event) => {
    setShowEditor(event.target.checked);
  };

  const run = async () => {

    setIsRunning(true);
    const toastId = toast.info('Executing code...', { autoClose: false });
    const starterCode = session.test_code.starter;
    const unitTest = session.test_code.unit_test;
    let pythonResult = [];

    const pyodide = await loadPyodide({
      indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.23.4/full/',
    });
    let pythonStdOut = '';
    pyodide.setStdout({
      isatty: false,
      batched: (output) => {
        pythonStdOut += output + '\n';
      },
    });

    let pythonStdErr = '';
    let passrate = 0;
    try {
      await pyodide.runPython(starterCode + '\n' + exampleCode + '\n' + unitTest);
      setOutput(pythonStdOut);
      setError('');
    } catch (err) {
      setOutput(err);
      pythonStdErr = err;
      setError(err);
    } finally {
      setIsRunning(false);
      toast.dismiss(toastId);
    }

    try {
      pythonResult = pyodide.globals.get('result').toJs();
      setTestResult(pythonResult);
      passrate = getPassrateByResult(pythonResult);
    } catch (err) {
      if (pythonStdErr === '') {
      }
    }
    const code ={
      creater_id: -currentUser.id,
      content: exampleCode,
      passrate: passrate ? passrate : 0,
      created_time: new Date(),
      keystrokes: []
    }
    console.log("code in presenter", code)
    
      let res = {}
      res.success = false
      let response = await axios.post(codeUrl + '/user/' + -currentUser.id, {
        code: {
          creater_id: -currentUser.id,
          content: exampleCode,
          passrate: passrate ? passrate : 0,
          created_time: new Date(),
          keystrokes: []
        },
      })
        .catch(error => {
          console.error(error)
        })
      res.code = response.data.code
      console.log(res)
      res.success = true
      return res
    
  };

  const handleRunCode = () => {
    setSelectedCode(exampleCode);
    run();
  };

  return (
    <div className="code-intructor-wrapper">
      <Box display="flex" alignItems="center">
        <Typography variant="h6" fontWeight="light" >
          Instructor Code
        </Typography>
        <Switch
          checked={showEditor}
          onChange={handleToggleChange}
          color="primary"
          sx={{ marginLeft: 1 }} 
        />
      </Box>
      {showEditor && (
        <>
        <CodeMirror
          className="codeTextArea"
          value={exampleCode}
          extensions={[python(), indentUnit.of('    ')]}
          onChange={handleCodeChange}
        />
        <Button onClick ={handleRunCode} disabled ={isRunning}>
          Run Code
        </Button>
        </>
      )}
    </div>
  );
};
export default ExampleCode;
