import { getUserSubmissions, getSessionSubmissions } from '../../service/submissionService'
import { getGroupsBySession } from '../../service/groupService'
import { getMessagesBySession } from '../../service/chatService'
import { getLeaderboard, getAvgAttempts } from "../../tool/progressUnit"
import React, { useContext, useEffect, useState } from 'react'
import { Toast } from '../commonUnit/Toast'
import { AuthContext } from '../../context/AuthContext'
import { showToast } from '../commonUnit/Toast'
import CodeArea from './CodeArea'
import CodeBar from './CodeBar'
import { getPassrateByResult } from '../../tool/progressUnit'
import { SelectedCodeContext } from '../../context/SelectedCodeContext'
import { addCodeAndUpdateUser } from '../../service/codeService'
import { SessionContext } from '../../context/SessionContext'
import PythonInterpreter from './PythonInterpreter'
import { loadPyodide } from 'pyodide'
import {
  createErr,
  createSubmission,
  updateSubmission,
  getSubmissionById,
} from '../../service/submissionService'
import { ModeContext } from '../../context/ModeContext'
import { ErrorContext } from '../../context/ErrorContext'
import Alert from '@mui/material/Alert'
import { toast } from 'react-toastify'
import '../../css/code.scss'
import { getUserById, getUserInSession } from '../../service/userService'
import { extractErrorInfo } from '../../service/errorService'
import { Box, Button, Divider, Grid, Typography  } from '@mui/material'
import { TestResultContext } from '../../context/TestResultContext'
import { PresenterListContext } from '../../context/PresenterListContext'
import { SubmissionsContext } from '../../context/SubmissionsContext'
import { SelectedGroupContext } from '../../context/SelectedGroupContext'
import { getCodeById, runCode, runJava, runCPP } from '../../service/codeService'
import { Modal } from '@mui/material'
import TextField from '@mui/material/TextField'
import { Textarea } from '@mui/joy'


const Code = () => {
  const { currentUser } = useContext(AuthContext)
  const { selectedCode, setSelectedCode, keystrokes, setKeystrokes, rerender, setRerender} = useContext(SelectedCodeContext)
  const { selectedGroup, setSelectedGroup} = useContext(SelectedGroupContext)
  const { Mode } = useContext(ModeContext)
  const { error } = useContext(ErrorContext)
  const [codeContent, setCodeContent] = useState('')
  const [blocklyCode, setBlocklyCode] = useState('')
  const [role, setRole] = useState(currentUser.role)
  const [latestCodeId, setLatestCodeId] = useState('')
  const { session } = useContext(SessionContext)
  //usestate to store the output of the python interpreter
  const [output, setOutput] = useState('')
  const [Err, setError] = useState('')
  const [isRunning, setIsRunning] = useState(false)
  const [user, setUser] = useState('No User Selected')
  const [passRate, setPassRate] = useState(0)
  const { setTestResult } = useContext(TestResultContext)
  const { codeList, setCodeList } = useContext(PresenterListContext)
  const [results, setResults] = useState([])
  const [expectedResults, setExpectedResults] = useState([])
  const { submissions } = useContext(SubmissionsContext)
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [userStats, setUserStats] = useState({
    attempts: 0,
    avgPassrate: 0,
    errors: 0
  })

  const [helperStats, setHelperStats] = useState({
    helpedCount: 0,
    helperRank: 0,
    messagesSent: 0
  })

  const [classStats, setClassStats] = useState({
    avgAttempts: 0,
    commonErrors: []
  })


  useEffect(() => {
    if (currentUser.role < 3 && selectedCode?.creater_id !== undefined) {
      fetchStats()
    }
  }, [selectedCode?.creater_id, currentUser.role, session.id])

  const handleClose = () => setOpen(false)

  const handleOpen = () => {
    setOpen(true)
    if (selectedCode === undefined) {
      return
    }
    if (selectedCode?.passrate !== 100) {
      const submission = submissions.find(
        (element) => element.code_id === selectedCode.id
      )
      if (submission === undefined) {
        return
      }
      if (submission?.error != null) {
        let textError = extractErrorInfo(submission.error)
        setName(textError.actualError)
      } else {
        let numFailed = 0
        submission.result_list?.forEach((result, index) => {
          if (!result) {
            numFailed += 1
          }
        })
        setName('Failed ' + numFailed + '/' + submission.result_list?.length)
      }
    } else {
      setName('Passed')
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  useEffect(() => {
    if (role !== currentUser.role) setRole(currentUser.role)
    if (currentUser.code_id) {
      setLatestCodeId(currentUser.code_id)
    }
  }, [currentUser])

  useEffect(() => {
    const handleSetCode = () => {
      setCodeContent(selectedCode.content)
    }
    async function fetchUser() {
      const selectedUser = await getUserById(selectedCode.creater_id)
      if (!selectedUser) return
      setUser(selectedUser.first_name)
      setPassRate(selectedCode.passrate)
      
      console.log(selectedUser)

    }
    if (selectedCode) {
      handleSetCode()
      fetchUser()
      fetchStats()
    }
  }, [selectedCode, session.id])

  useEffect(() => {
    //console.log(selectedGroup)
    if(selectedGroup!=null &&selectedGroup.code_id!=null){
      getCodeById(selectedGroup.code_id).then((code)=>{
        setSelectedCode(code)
        setLatestCodeId(code.id)
      })
    }
  }, [selectedGroup])

  if (!session) return null
  const fetchStats = async () => {
    try {
      // Fetch user submissions
      let user_submissions = await getUserSubmissions(session.id, selectedCode.creater_id)
      console.log(user_submissions)
      user_submissions.sort((x, y) => new Date(x.created_time) - new Date(y.created_time))

      

      // Calculate user stats
      const attempts = user_submissions.length
      const passSubmissions = user_submissions.map(submission => submission.result_list).filter(pass => pass !== null)
      let passCount = 0
      passSubmissions.forEach(pass => {
        pass.forEach(p => {
          if (p) passCount++
        })
      })
      let avgPassrate = Math.round(passCount * 100 / (user_submissions.length * session?.test_list?.length))
      if(session?.test_list?.length===undefined){
        let passTotal = 0
        for (let element of user_submissions){
          if(element.result_list!=null){
            element.passrate =  Math.round(element?.result_list?.filter(Boolean).length * 100 / ( session.test_list?.length))
            passTotal+=element.passrate
          }
          else{
            let code = await getCodeById(element.code_id)
            passTotal+=code.passrate
            element.passrate = code.passrate
          }
        }
        console.log(Math.round(passTotal/user_submissions.length))
        avgPassrate = Math.round(passTotal/user_submissions.length)
      }
      const errors = submissions.filter(element => element?.error !== null).length

      setUserStats({ attempts, avgPassrate, errors })

      // Fetch helper stats
      const groups = await getGroupsBySession(session.id)
      const leaderboardDict = await getLeaderboard(session, groups)
      const leaderboard = Object.values(leaderboardDict).sort((a, b) => b.count - a.count)
      const rank = leaderboard.findIndex(element => element.user.id === selectedCode.creater_id)
      const helpedCount = leaderboard[rank]?.count || 0

      // Fetch messages
      const messages = await getMessagesBySession(session.id)
      const userMessages = messages.filter(message => message.sender_id == selectedCode.creater_id)
      const messagesSent = userMessages.length

      setHelperStats({ helpedCount, helperRank: rank + 1, messagesSent })

      // Fetch class stats
      const avgAttempts = await getAvgAttempts(session.id, session?.test_list?.length)
      const allSubmissions = await getSessionSubmissions(session.id)
      const errorCounts = {}
      allSubmissions.forEach(sub => {
        if (sub.error !== null) {
          const error = extractErrorInfo(sub.error)
          if(error!=null){
            errorCounts[error.errorType] = (errorCounts[error.errorType] || 0) + 1
          }
          
        }
      })
      const commonErrors = Object.entries(errorCounts)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 3)  // Get top 3 most common errors

      setClassStats({ avgAttempts, commonErrors })

    } catch (error) {
      console.error("Error fetching stats:", error)
    }
  }


  const handleSetCode = (name) => {
    if (!Array.isArray(codeList)) {
      //console.log('codeList is not an array')
      return
    }
    const newCodeList = [...codeList]
    newCodeList.push({
      name: name,
      code: selectedCode,
    })
    setCodeList(newCodeList)
    setOpen(false)
  }

  const handleCodeChange = (value) => {
    setCodeContent(value)
  }

  const handleSaveCode = async (passrate, pythonResult) => {
    if (currentUser.role < 3) {
      showToast("You don't have permission to change the code", 'error', 2000)
    }

    const result = await addCodeAndUpdateUser(
      codeContent,
      passrate,
      currentUser.id
    )
    if (result.success) {
      showToast('Code saved successfully!', 'success', 2000)
      setSelectedCode(result.code)

      setLatestCodeId(result.code.id)
      return result.code
    } else {
      showToast('Code saved failed!', 'error', 2000)
      //console.log('Error:', result.error)
      return null
    }
  }
  const runJavaCode = async (e) => {
    setOutput("")
    setIsRunning(true)
    const toastId = toast.info('Executing code...', { autoClose: false })

    let result = await runJava(codeContent,session.id)
    let passrate = 0
    let javaResult=[]
    let javaErr = null
    console.log(result)
    if(result.isSucceed){
      javaResult = result.executionResult.stderr.split("\n")
      javaResult.splice(javaResult.length-1)
      javaResult = javaResult.map(e => e === 'true'||e === 'true\r')
      passrate = getPassrateByResult(javaResult)
      setTestResult(javaResult)
      setOutput(result.executionResult.stdout)
      
    }
    else{
      setOutput(result.executionResult.stderr)
      let lines = result.executionResult.stderr.split("\n")
      javaErr={
        message:result.executionResult.stderr,
        name:lines[lines.length-1]
      }
      setTestResult(javaResult)
    }

    setIsRunning(false)
    toast.dismiss(toastId)
    console.log(selectedCode)
    console.log(javaResult)
    if(selectedCode.creater_id==null || selectedCode.creater_id==currentUser.id){
      const code = await handleSaveCode(passrate, null)
      console.log(code)
      if (code) {
        const submission = createSubmission(
          currentUser.id,
          code.id,
          session.id,
          javaResult,
          passrate,
          javaErr
        )
        console.log(submission)
        await updateSubmission(submission)
      }
    }
  }

  const run = async (e) => {
    if(session.type.startsWith("Java")){
      try {
        runJavaCode(e)
      } catch (error) {
        console.log(error)
      }
      return
    }
    setIsRunning(true)
    const toastId = toast.info('Executing code...', { autoClose: false })
    const starterCode = session.test_code.starter
    const unitTest = session.test_code.unit_test
    let pythonResult = []

    const pyodide = await loadPyodide({
      indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.23.4/full/',
    })
    let pythonStdOut = ''
    pyodide.setStdout({
      isatty: false,
      batched: (output) => {
        pythonStdOut += output + '\n'
      },
    })

    let pythonStdErr = ''
    let passrate = 0
    //Run Starter Code and User Code; Output to Python Interpreter
    try {
      await pyodide.runPython(
        starterCode + '\n' + codeContent + '\n' + unitTest
      )
      setOutput(pythonStdOut)
      setError('')
    } catch (err) {
      //console.log(err.toString())
      setOutput(err)
      pythonStdErr = err
      setError(err)
    } finally {
      setIsRunning(false)
      toast.dismiss(toastId)
    }
    try {
      pythonResult = pyodide.globals.get('result').toJs()
      //console.log(pythonResult)
      setTestResult(pythonResult)
      passrate = getPassrateByResult(pythonResult)
    } catch (err) {
      //console.log(err.toString())
      if (pythonStdErr === '') {
        // setOutput(pythonStdOut + '\n' + 'No return value')
      }
    }

    //Save Code
    const code = await handleSaveCode(passrate, pythonResult)
    if (code) {
      const submission = createSubmission(
        currentUser.id,
        code.id,
        session.id,
        pythonResult,
        passrate,
        createErr(pythonStdErr)
      )
      await updateSubmission(submission)
    }
  }
  const handleBlocklyChange = (code,blocksUsed) => {
    setCodeContent(code)
  }

  const runJavaCodePresenter = async (e) => {
    setOutput("")
    setIsRunning(true)
    const toastId = toast.info('Executing code...', { autoClose: false })

    let result = await runJava(codeContent,session.id)
    let passrate = 0
    let javaResult=[]
    let javaErr = null
    console.log(result)
    if(result.isSucceed){
      javaResult = result.executionResult.stderr.split("\n")
      javaResult.splice(javaResult.length-1)
      javaResult = javaResult.map(e => e === 'true'||e === 'true\r')
      passrate = getPassrateByResult(javaResult)
      setTestResult(javaResult)
      setOutput(result.executionResult.stdout)
      
    }
    else{
      setOutput(result.executionResult.stderr)
      let lines = result.executionResult.stderr.split("\n")
      javaErr={
        message:result.executionResult.stderr,
        name:lines[lines.length-1]
      }
      setTestResult(javaResult)
    }

    setIsRunning(false)
    toast.dismiss(toastId)
    console.log(selectedCode)
    console.log(javaResult)
    
  }

  const runCppCodePresenter = async (e) => {
    setOutput("")
    setIsRunning(true)
    const toastId = toast.info('Executing code...', { autoClose: false })

    let result = await runCPP(codeContent,session.id)
    let passrate = 0
    let javaResult=[]
    let javaErr = null
    console.log(result)
    if(result.isSucceed){
      javaResult = result.executionResult.stderr.split("\n")
      javaResult.splice(javaResult.length-1)
      javaResult = javaResult.map(e => e === '1'||e === '1\r')
      passrate = getPassrateByResult(javaResult)
      setTestResult(javaResult)
      setOutput(result.executionResult.stdout)
      
    }
    else{
      setOutput(result.executionResult.stderr)
      let lines = result.executionResult.stderr.split("\n")
      javaErr={
        message:result.executionResult.stderr,
        name:lines[lines.length-1]
      }
      setTestResult(javaResult)
    }

    setIsRunning(false)
    toast.dismiss(toastId)
    console.log(selectedCode)
    console.log(javaResult)
    
  }

  const runPresenterSQL = async (e) => {
    setIsRunning(true)
    const toastId = toast.info('Executing code...', { autoClose: false })
    const unitTest = session.test_code.unit_test
    

    let result = await runCode(codeContent,unitTest,session.type==="SQL Schema"?"Schema":"Select",session.id)
    console.log(result)
    let pRate = 0
    let error = null
    if(result.message!=null){
      error = result
      setOutput(result.message)
      setResults([])
      setExpectedResults([])
      pRate = 0
    }
    else{
      let passR = result.result
      pRate = passR
      let tables
      let expected
      if(session.type == "SQL Schema"){
        for(let i = 0;i<result.data[0].values.length;i++){
          result.data[i+1].name = result.data[0].values[i]
        }
        for(let i = 0;i<result.expected[0].values.length;i++){
          result.expected[i+1].name = result.expected[0].values[i]
        }
        tables = result.data.slice(1)
        expected = result.expected.slice(1)
        console.log(tables)
        console.log(expected)
      }
      else{
        tables = result.data
        expected = result.expected
        console.log(tables)
        console.log(expected)
      }
      
      if(passR === 100){
        setOutput("Success")
        setResults([])
        setExpectedResults([])
      }
      else{
        setOutput("Failed")
        setResults(tables)
        setExpectedResults(expected)
      }
      
    }
    
    setError('')
    console.log(selectedCode)
    console.log(codeContent)
    
    setIsRunning(false)
    toast.dismiss(toastId)
    //Save Code
    
  }
  

  const runPresenterView = async () => {
    if(session.type.startsWith("Java")){
      try {
        runJavaCodePresenter(null)
      } catch (error) {
        console.log(error)
      }
      return
    }
    if(session.type=="CPP"){
      try {
        runCppCodePresenter(null)
      } catch (error) {
        console.log(error)
      }
      return
    }
    setIsRunning(true)
    const toastId = toast.info('Executing code...', { autoClose: false })
    const starterCode = session.test_code?.starter
    const unitTest = session.test_code?.unit_test
    let pythonResult = []

    const pyodide = await loadPyodide({
      indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.23.4/full/',
    })
    let pythonStdOut = ''
    pyodide.setStdout({
      isatty: false,
      batched: (output) => {
        pythonStdOut += output + '\n'
      },
    })

    let pythonStdErr = ''
    let passrate = 0
    //Run Starter Code and User Code; Output to Python Interpreter
    try {
      await pyodide.runPython(
        starterCode + '\n' + codeContent + '\n' + unitTest
      )
      setOutput(pythonStdOut)
      setError('')
    } catch (err) {
      //console.log(err.toString())
      setOutput(err)
      pythonStdErr = err
      setError(err)
    } finally {
      setIsRunning(false)
      toast.dismiss(toastId)
    }
    try {
      pythonResult = pyodide.globals.get('result').toJs()
      //console.log(pythonResult)
      setTestResult(pythonResult)
      passrate = getPassrateByResult(pythonResult)
    } catch (err) {
      //console.log(err.toString())
      if (pythonStdErr === '') {
        // setOutput(pythonStdOut + '\n' + 'No return value')
      }
    }
  }
  function indexOfArray(val, array) {
    var hash = {};
    for (var i = 0; i < array.length; i++) {
      hash[array[i]] = i;
    }
    return (hash.hasOwnProperty(val)) ? hash[val] : -1;
  };

  if (currentUser.role === 3) {
    window.addEventListener('beforeunload', function (e) {
      var message =
        'Your code will be lost if you leave this page. Please run your code before leaving.'
      e.returnValue = message
      return message
    })
  }
  console.log(user)

  return (
    <div className="code-container">
      {currentUser.role < 3 && (
        
        <div className="code">
                

          {Mode == false && (
              <div className="student-info-container">
                {/* <Typography className ="studentInfo-title"> */}
                <Typography variant="h6" fontWeight={'light'} sx={{margin: 1, marginLeft: 2, marginTop:2}}>
                  Student Information
                </Typography>
                <div className="embeddedCodeBar">        
                  <div className="stats-container">
                    <div className="column left-column">
                      <h4>Student Stats: {user} </h4>

                      <p>Attempts: {userStats.attempts}</p>
                      <p className="highlight">{passRate}% Tests Passed</p>
                      <p>Avg Passrate: {userStats.avgPassrate}%</p>
                      <p>Errors: {userStats.errors}</p>
                    </div>
                    {session?.type === "Helper/Helpee" && (<div className="column right-column">
                      <h4>Helper Statistics</h4>
                      <p>Helped {helperStats.helpedCount} students</p>
                      <p className="highlight">Helper Rank: #{helperStats.helperRank}</p>
                      <p>Sent {helperStats.messagesSent} Messages</p>
                      <p>User: {user}</p>
                    </div>)}
                  </div>
                </div>
              </div>

          )}

          <div className="code-presenter-wrapper">  
            <CodeArea
              onCodeChange={session.type?.startsWith("Blockly")?handleBlocklyChange:handleCodeChange}
              saveCode={handleSaveCode}
              runCode={session.type?.startsWith("SQL")?runPresenterSQL:runPresenterView}
            />
          </div>
          {Mode == false && (
            <>
              <Button className = "add-to-presenter-button"variant="contained" onClick={handleOpen}>
                Add to Presenter View
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2">
                    Add Code To Presenter List
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <Textarea
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => handleSetCode(name)}>
                      Add
                    </Button>
                  </div>
                </Box>
              </Modal>
            </>
          )}

            {Mode == true && (
              <>
              <div className="container">
                {session.type?.startsWith("SQL") &&(
                  <span key={rerender}>
                    <div style={{display:"flex",overflowX:"scroll",marginTop:".5em"}}>
                      <div>
                        <Typography>Your Output</Typography>
                        <div >
                          <div >
                            <Grid container  >
                              {results.map(({ columns, values, name }, i) => (
                                <table>
                                  <caption>{name}</caption>
                                  <thead>
                                    <tr>
                                      {columns.map((columnName, j) => {

                                      if(expectedResults[i]!==undefined&&expectedResults[i]?.columns[j]!==undefined &&expectedResults[i]?.columns[j]===results[i]?.columns[j]){
                                        return (
                                          <td style={{backgroundColor:"#ffffff"}} key={j}>{columnName}</td>
                                        )
                                      }
                                      else{
                                        return (
                                          <td style={{backgroundColor:"#EE4B2B"}} key={j}>{columnName}</td>
                                        )
                                      }

                                    })}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {
                                      // values is an array of arrays representing the results of the query
                                      values.map((row, j) => (
                                        <tr key={j}>
                                          {row.map((value, k) => {

                                            if(results[i]!==undefined&&((indexOfArray(results[i]?.values[j],expectedResults[i]?.values)!==-1)||(expectedResults[i]?.values[j]!==undefined &&expectedResults[i]?.values[j][k]!==undefined&&expectedResults[i]?.values[j][k]===results[i]?.values[j][k]))){
                                              return (
                                                <td style={{backgroundColor:"#ffffff"}} key={k}>{value}</td>
                                              )
                                            }
                                            else{
                                              return (
                                                <td style={{backgroundColor:"#EE4B2B"}} key={k}>{value}</td>
                                              )
                                            }

                                            
                                          })}
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              ))}
                            </Grid>
                            
                          </div>
                        </div>
                      </div>
                      <Divider orientation="vertical" flexItem />
                      <div >
                        {expectedResults.length>0 &&(
                          <Typography>Expected Output</Typography>
                        )}
                        <div >
                          <div >
                            <Grid container  >
                              {expectedResults.map(({ columns, values, name }, i) => (
                                <table>
                                  <caption>{name}</caption>
                                  <thead>
                                    <tr>
                                      {columns.map((columnName, j) => {

                                        if(results[i]!==undefined&&results[i]?.columns[j]!==undefined &&results[i]?.columns[j]===expectedResults[i]?.columns[j]){
                                          return (
                                            <td style={{backgroundColor:"#ffffff"}} key={j}>{columnName}</td>
                                          )
                                        }
                                        else{
                                          return (
                                            <td style={{backgroundColor:"#32CD32"}} key={j}>{columnName}</td>
                                          )
                                        }

                                      })}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {
                                      // values is an array of arrays representing the results of the query
                                      values.map((row, j) => (
                                        <tr key={j}>
                                          {row.map((value, k) => {

                                            if(results[i]!==undefined&&((indexOfArray(expectedResults[i]?.values[j], results[i]?.values)!==-1)||(results[i]?.values[j]!==undefined &&results[i]?.values[j][k]!==undefined&&results[i]?.values[j][k]===expectedResults[i]?.values[j][k]))){
                                              return (
                                                <td style={{backgroundColor:"#ffffff"}} key={k}>{value}</td>
                                              )
                                            }
                                            else{
                                              return (
                                                <td style={{backgroundColor:"#32CD32"}} key={k}>{value}</td>
                                              )
                                            }
                                            
                                          })}
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              ))}
                            </Grid>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                  
                  
                )}
                
              </div>
              <div className="codeOutputWrapper">
                <Typography variant="h6" sx={{ fontWeight: 'light' }}>
                  Output
                </Typography>
                <PythonInterpreter output={output} />
              </div>
              </>

            )}
          { user !== 'No User Selected' &&
          (<CodeBar
            handleSaveCode={handleSaveCode}
            latestCodeId={latestCodeId}
            runCode={runPresenterView}

            displayMode="embedded"
          />)}

          


          
        </div>
        
      )}
      <Toast />
      {currentUser.role === 3 && (
        <div className="code-stud">
          <div className="container">
            <CodeBar
              handleSaveCode={handleSaveCode}
              latestCodeId={latestCodeId}
              runCode={run}
              isRunning={isRunning}
            />

            <CodeArea
              onCodeChange={session.type.startsWith("Blockly")?handleBlocklyChange:handleCodeChange}
              saveCode={handleSaveCode}
            />
          </div>
          {/* <div className="container">
            <TestSelect />
          </div> */}
          <div className="container">
           <div className ="codeOutputWrapper">
            <Typography variant="h6" sx={{ fontWeight: 'light' }}>
              Output
            </Typography>
            <PythonInterpreter output={output} />
          </div>
          </div>
          <Toast />
        </div>
      )}
    </div>
  )
}

export default Code