import React, { useState } from 'react';
import '../../css/landing/aboutUs.scss';
import { Typography } from '@mui/material';

const profiles = [
  { name: 'Yan Chen', role: 'Project Manager', imageUrl: 'https://chensivan.github.io/files/headshot1.jpg', website: 'https://chensivan.github.io/' },
  { name: 'Xiaohang Tang', role: 'Developer', imageUrl: 'https://chensivan.github.io/img/profile_image/xiaohang_tang.jpeg', website: 'https://xiaohang-tang.github.io/' },
  { name: 'Xi Chen', role: 'Developer', imageUrl: 'https://chensivan.github.io/img/profile_image/xi_chen.jpg', website: 'https://11chen.link/' },
  { name: 'Tejas Navada', role: 'Developer', imageUrl: 'https://t3.ftcdn.net/jpg/06/19/26/46/360_F_619264680_x2PBdGLF54sFe7kTBtAvZnPyXgvaRw0Y.jpg', website: 'https://sites.google.com/view/tejas-navada-portfolio/home' },
  { name: 'Susan He', role: 'Tester', imageUrl: 'https://t3.ftcdn.net/jpg/06/19/26/46/360_F_619264680_x2PBdGLF54sFe7kTBtAvZnPyXgvaRw0Y.jpg', website: 'https://zichenghe-susan.github.io/susanhe.github.io/' },
  { name: 'Yusi Yao', role: 'Developer', imageUrl: 'https://yusiyao.org/author/yusi-yao-%E5%A7%9A%E7%BE%BD%E6%80%9D/avatar_hue4deb600174ddfc4efc907f96052d86f_689773_150x150_fill_q75_lanczos_center.jpg', website: 'https://yusiyao.org' },
  { name: 'Rexime Abulikemu', role: 'Designer', imageUrl: 'https://chensivan.github.io/img/profile_image/rehema.jpeg', website: 'https://rexime.github.io/' },
  { name: 'Ninglan Lei', role: 'Support', imageUrl: 'https://t3.ftcdn.net/jpg/06/19/26/46/360_F_619264680_x2PBdGLF54sFe7kTBtAvZnPyXgvaRw0Y.jpg', website: 'https://chensivan.github.io/' },
  { name: 'Bogdan Perlroth', role: 'Developer', imageUrl: 'https://t3.ftcdn.net/jpg/06/19/26/46/360_F_619264680_x2PBdGLF54sFe7kTBtAvZnPyXgvaRw0Y.jpg', website: 'https://www.linkedin.com/in/bogdan-perl/' },
  { name: 'Sam Wong', role: 'Designer', imageUrl: 'https://chensivan.github.io/img/profile_image/samwong.jpg', website: 'https://www.linkedin.com/in/sam-wong-34a48a157/' },
];
const articles = [
    {
      authors: 'Xiaohang Tang, Sam Wong, Kevin Pu, Xi Chen, Yalong Yang, Yan Chen',
      title: 'VizGroup: An AI-Assisted Event-Driven System for Real-Time Collaborative Programming Learning Analytics',
      conference: 'UIST 2024',
      imageUrl: 'https://chensivan.github.io/img/vizgroup.png',
      preprintLink: 'https://chensivan.github.io/papers/vizgroup_uist_2024.pdf', 
      demo30secLink: 'https://www.dropbox.com/scl/fi/gmfexd5qd0il12zuxg7m6/uist-preview.mp4?rlkey=34j2i3odgau6visikzae9ttq6&dl=0', 
      demo5minLink: 'https://www.dropbox.com/scl/fi/ovfsmqllqd1zt5igx5onl/uist-demo.mp4?rlkey=s49cru2syqphiwjnjio9du3g4&dl=0', 
    },
    {
      authors: 'Ashley Zhang, Xiaohang Tang, Steve Oney, Yan Chen',
      title: "CFlow: Supporting Semantic Flow Analysis of Students' Code in Programming Problems at Scale",
      conference: 'ACM Learning @ Scale 2024 (L@S) \'24',
      imageUrl: 'https://chensivan.github.io/img/cflow.png',
      pdfLink: 'https://arxiv.org/pdf/2404.10089', 
      arxivLink: 'https://arxiv.org/abs/2404.10089', 
      talkVideoLink: 'https://www.dropbox.com/s/mlw6y2r5ceyjesz/CFlow-v1.m4v?dl=0', 
    },
    {
      authors: 'Ashley Zhang, Yan Chen, Steve Oney',
      title: "VizProg: Identifying Misunderstandings by Visualizing Students' Coding Progress",
      conference: 'ACM Conference on Human Factors in Computing Systems (CHI), 2023 (🏅Best Paper Honorable Mention)',
      imageUrl: 'https://chensivan.github.io/img/vizprog.png',
      pdfLink: 'https://chensivan.github.io/papers/chi2023_vizprog.pdf', 
      demo9minLink: 'http://localhost:3000/about#',
      description: "VizProg presents an innovative, real-time visualization system that meaningfully displays class-wide students' coding progress, making it simple for programming instructors to pinpoint issues and mistakes.",
      thoughts: "The challenge of delivering programming education at scale is exacerbated by factors such as real-time instruction, a large student population, novice learners, and the complexity of the material being taught. To enhance the classroom experience for students and simplify the task for instructors, how can we make programming education more engaging and manageable?"
    },
  ];

const AboutUsPanel = () => {

    return (
        <>
        <div className="about-us">
            <div className="header-container">
                <Typography variant='h2' className="header-text">
                    Our team
                </Typography>
                <Typography variant='body1' className="intro-text">
                VizPI is a platform developed by a dedicated group of researchers, instructors, student designers, and student engineers from institutions like Virginia Tech, University of Virginia, University of Washington, and more, all united by a passion for transforming education. Our platform is designed to help instructors conduct dynamic in-class coding exercises that engage students in meaningful peer interactions while providing deep insights into their cognitive processes and understanding of programming concepts.                </Typography>
                <hr className="division-line" />
                <br/>
            </div>

            <div className="profile-container">
                {profiles.map((profile, index) => (
                    <div className="profile" key={index}>
                        <div className="profile-description">
                            {profile.role}
                        </div>
                        <div className="profile-pic" style={{ backgroundImage: `url(${profile.imageUrl})` }}></div>
                        <a href={profile.website} target="_blank" rel="noopener noreferrer" className="profile-name">
                            {profile.name}
                        </a>
                    </div>
                ))}
            </div>
            <br/>
            <hr className="division-line"/>
            
            </div>


            <div className="intro-container">
            
                <div className="header-container">
                    <Typography variant='h3' className="exersice-header-text">
                        Previous Findings
                    </Typography>
                    <Typography variant='body1' className="intro-text">
                        Our previous findings highlight the positive impact of AI-assisted monitoring on collaborative programming learning. Instructors reported improved student engagement and more effective collaboration, with AI-generated insights helping to identify and address learning gaps promptly. These results demonstrate the potential of VizGroup to transform educational experiences by fostering deeper understanding and teamwork.
                    </Typography>
                    <br/>
                    <hr className="division-line" />
                </div>
                </div>
                <div className="about-us">
                <div className="articles-container">
                    {articles.map((article, index) => (
                        <div className="article" key={index}>
                            <div className="article-image" style={{ backgroundImage: `url(${article.imageUrl})` }} />
                            <div className="article-content">
                                <Typography variant='h6' className="article-title">
                                    {article.authors}. {article.title}. {article.conference}
                                </Typography>
                                <div className="article-meta">
                                    <div className="article-links">
                                        {article.preprintLink && (
                                            <a href={article.preprintLink} target="_blank" rel="noopener noreferrer" className="article-link">
                                                pre-print
                                            </a>
                                        )}
                                        {article.demo30secLink && (
                                            <>
                                                {' | '}
                                                <a href={article.demo30secLink} target="_blank" rel="noopener noreferrer" className="article-link">
                                                    30 second demo
                                                </a>
                                            </>
                                        )}
                                        {article.demo5minLink && (
                                            <>
                                                {' | '}
                                                <a href={article.demo5minLink} target="_blank" rel="noopener noreferrer" className="article-link">
                                                    5 min demo
                                                </a>
                                            </>
                                        )}
                                        {article.pdfLink && (
                                            <a href={article.pdfLink} target="_blank" rel="noopener noreferrer" className="article-link">
                                                local pdf
                                            </a>
                                        )}
                                        {article.arxivLink && (
                                            <>
                                                {' | '}
                                                <a href={article.arxivLink} target="_blank" rel="noopener noreferrer" className="article-link">
                                                    arxiv
                                                </a>
                                            </>
                                        )}
                                        {article.talkVideoLink && (
                                            <>
                                                {' | '}
                                                <a href={article.talkVideoLink} target="_blank" rel="noopener noreferrer" className="article-link">
                                                    talk video
                                                </a>
                                            </>
                                        )}
                                        {article.demo9minLink && (
                                            <>
                                                {' | '}
                                                <a href={article.demo9minLink} target="_blank" rel="noopener noreferrer" className="article-link">
                                                    9 min system demo
                                                </a>
                                            </>
                                        )}
                                    </div>
                                    {article.description && (
                                        <Typography variant="body2" className="article-description">
                                            {article.description}
                                        </Typography>
                                    )}
                                    {article.thoughts && (
                                        <Typography variant="body2" className="article-thoughts">
                                            Thoughts: {article.thoughts}
                                        </Typography>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            </>

    );
};

export default AboutUsPanel;