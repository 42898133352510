import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import ExersiceTutorial from './ExersiceTutorial';
import '../../css/landing/explore.scss';
import demo from '../../icon/about/demo-video.mp4';

const ExplorePanel = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [exercise, setExercise] = useState(""); // State for exercise
    const [currentStatIndex, setCurrentStatIndex] = useState(0); // State for current statistic index

    const handleTutorialClick = (selectedExercise) => {
        setModalOpen(true);
        setExercise(selectedExercise);
    }

    const statistics = [{
        title: "Class Sessions Conducted",
        value: 33
    },
    {
        title: "Students Engaged in VizPI-Powered Sessions",
        value: 2586
    },
    {
        title: "Groups Formed in Real-Time Classes",
        value: 765
    }];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentStatIndex((prevIndex) => (prevIndex + 1) % statistics.length);
        }, 5000); // Change statistic every 5 seconds
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <div className="intro-container">
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <Typography variant='h2' className="header-text">
                            Welcome to VizPI
                        </Typography>
                        <Typography variant='body1' className="intro-text">
                        Our platform VizPI (visualizing peer interactions) is designed to help instructors conduct dynamic in-class coding exercises that not only engage students in meaningful peer interactions but also provide deep insights into their cognitive processes and understanding of programming concepts. By facilitating real-time collaboration, discussion, and problem-solving among students, these exercises go beyond traditional assignments, revealing critical aspects of students' mental models, teamwork skills, and learning progress.                       </Typography>
                        
                    </Grid>
                    <Grid item xs={4}>
                        <div className="stat-container">
                            
                            <Typography variant='h4'  className="descirption-value">
                                {statistics[currentStatIndex].value}
                            </Typography>
                            <Typography variant='h6' className="descirption-text">
                                {statistics[currentStatIndex].title}
                            </Typography>
                        </div>
                    </Grid>
                    
                </Grid>
                <br />
                <hr className="division-line" />
                <br />
                <Typography variant='body1' className="intro-text">
                In today’s rapidly evolving educational landscape, it’s not enough to rely solely on assignment outcomes to gauge student understanding. The rich flow of information generated during peer interactions plays a vital role in learning programming, and ultimately, in future software development careers. However, existing tools often fall short in capturing and utilizing this data to provide context-aware support.
                </Typography> 
                <br />
                <Typography variant='body1' className="intro-text">
                Our platform addresses this gap by continuously monitoring and interpreting students' interactions, giving you, the instructor, a clearer picture of where students may be struggling or excelling. This enables you to provide targeted feedback and interventions that are both timely and effective, ensuring that your students not only keep up with the material but truly master it. By leveraging this tool, you can guide your class more effectively, enhance learning outcomes, and prepare your students for the challenges of real-world software development.                </Typography> 
                <br />
            <Typography variant='body1' className="intro-text">
                Click on the video below to see a brief intro on our platform.
            </Typography>
            <br />
                <hr className="division-line" />
                <br />
            </div>
            
            <div className="video-container">
                <iframe

                    src="https://www.youtube.com/embed/wkGMtVkP6oQ"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube Video"
                    style={{ width: "100%", height: "40vh" }}
                ></iframe>
            </div>
            
            <div className="intro-container">
            <br />
                <Typography variant='h2' className="exersice-header-text">
                    Exercise Overview
                </Typography>
                <Typography variant='body1' className="intro-text">
                    VizPI has developed a range of innovative exercise. Click on any exercise to view a brief tutorial on how to use it and to understand the distinctions between the instructor's and the student's perspectives.
                </Typography>
                <br />
                <hr className="division-line" />
            </div>
            <div className="grid-container">
                <div className="grid-item" onClick={() => handleTutorialClick("Auto Grouping")}>
                    <div className="grid-item-description">
                        Facilitates grouping mechanisms that allow for peer instructions and group discussions
                    </div>
                    <div className="hexagon-background"></div>
                    <div className="grid-item-content">
                        Auto Grouping
                    </div>
                </div>
                <div className="grid-item" onClick={() => handleTutorialClick("Audio Discussion")}>
                    <div className="grid-item-description">
                        Generates transcripts of real-time discussions and provides AI-generated insights into the progress of the conversations
                    </div>
                    <div className="hexagon-background"></div>
                    <div className="grid-item-content">
                        Audio Discussion
                    </div>
                </div>
                <div className="grid-item" onClick={() => handleTutorialClick("Helper/Helpee")}>
                    <div className="grid-item-description">
                        Allows students who have mastered the tasks to assist struggling peers through a chat system
                    </div>
                    <div className="hexagon-background"></div>
                    <div className="grid-item-content">
                        Helper/Helpee
                    </div>
                </div>
                <div className="grid-item" onClick={() => handleTutorialClick("VizMental")}>
                    <div className="grid-item-description">
                        Provides a personalized AI assistant that guides and encourages students throughout their coding process
                    </div>
                    <div className="hexagon-background"></div>
                    <div className="grid-item-content">
                        Vizmental
                    </div>
                </div>
            </div>
            {modalOpen && <ExersiceTutorial exercise={exercise} onClose={() => setModalOpen(false)} />}
        </div>
    );
};

export default ExplorePanel;
