import { useContext, useEffect } from 'react'
import { useState } from 'react'
import ProgressBar from './ProgressBar'
import { getAvatar } from '../../tool/Tools'
import { getSubmissionInContextById } from '../../service/submissionService'
import { SelectedGroupContext } from '../../context/SelectedGroupContext'
import { SubmissionsContext } from '../../context/SubmissionsContext'
import { getParticipationByUserId, getGroupsByUser, updateGroupName } from '../../service/groupService'
import { SelectedCodeContext } from '../../context/SelectedCodeContext'
import { SelectedUsersContext } from '../../context/SelectedUserContext'
import { SessionContext } from '../../context/SessionContext'
import { AuthContext } from '../../context/AuthContext'
import { getCodeByUser } from '../../service/sessionService'
import {getCodeById} from '../../service/codeService'
import {getUserById, updateUser} from '../../service/userService'
import TerminalIcon from '@mui/icons-material/Terminal';
import { Switch, Typography, TextField } from '@mui/material'
import { showToast } from '../commonUnit/Toast'
import { filterProfanity } from '../../tool/profanityFilter'

const UserView = ({ user }) => {
  const { selectedGroup,setSelectedGroup } = useContext(SelectedGroupContext)
  const { submissions } = useContext(SubmissionsContext)
  const [passrate, setPassrate] = useState(0)
  const [conversationRate, setConversationRate] = useState(0)
  const { selectedUsers } = useContext(SelectedUsersContext)
  const { selectedCode, setSelectedCode } = useContext(SelectedCodeContext)
  const { currentUser } = useContext(AuthContext)
  const { session } = useContext(SessionContext)
  const [anon, setAnon] = useState(user.nick_name==null)
  const [nickname, setNickname] = useState(user?.nick_name!=null?user?.nick_name:"")

  useEffect(() => {
    const submission = getSubmissionInContextById(submissions, user.id)
    setPassrate(submission ? submission.passrate : 0)
    setConversationRate(
      getParticipationByUserId(selectedGroup.messages, user.id)
    )
  }, [user, submissions])
  useEffect(() => {
    if(anon && user.nick_name!=null){
      setNickname("")
      user.nick_name=null
      updateUser(user)
      getGroupsByUser(user.id,session.id).then((groups)=>{
        for(let i = 0;i<groups.length;i++){
          updateGroupName(groups[i].id,groups[i].name+" ")
          updateGroupName(groups[i].id,groups[i].name)
        }
      })
    }
  }, [anon])

  if (
    !selectedUsers ||
    selectedUsers.length === 0 ||
    selectedUsers[0] === null
  ) {
    return null
  }

  const handleImageClick = async (user) => {
    if(currentUser.role==3&&session.type!="Helper/Helpee"&&!session.type.startsWith("SQL")){
      return;
    }
    let updatedSelf = await getUserById(currentUser.id)
    let selfCode =await getCodeById(updatedSelf.code_id)
    if(currentUser.role < 3 || selfCode?.passrate===100){
      // //console.log("clicked icon", user, user?.code_id)
      let updatedUser = await getUserById(user.id)
      const code = await getCodeById(updatedUser?.code_id)
       //console.log("clicked icon", user,code, user?.code_id)
      setSelectedCode(code)
    }
    
  }
  if(user.role<3){
    return null
  }

  if (!user) {
    return <div>Loading...</div>
  }

  return (
    <div style={{display:"flex"}}>
      <div className="userView" style={{border: user.id === currentUser.id ?'1px solid rgba(0, 0, 0, 0.5)' : '1px solid rgba(0, 0, 0, 0.0)'}}>
        <div className="userViewHeader" onClick={() => handleImageClick(user)}>
          <img
            src={getAvatar(user?.avatar_url)}
            alt={`${user?.first_name}'s avatar`}
            className={selectedCode?.creater_id === user.id ? 'selected' : ''}
          />
          {user.id===currentUser.id && !anon &&(
            <TextField
            className="textField"
            variant="standard"
            value={nickname}
            size="medium"
            inputProps={{ style: { fontSize: "14px" } }}
            onChange={(e) => {
              setNickname(e.target.value)
            }}
            onBlur={(e) => {
              user.nick_name=filterProfanity(nickname)
              updateUser(user)
              setSelectedGroup(selectedGroup)
              getGroupsByUser(user.id,session.id).then((groups)=>{
                for(let i = 0;i<groups.length;i++){
                  updateGroupName(groups[i].id,groups[i].name+" ")
                  updateGroupName(groups[i].id,groups[i].name)
                }
              })
            }}
          ></TextField>
          )}
          {(user.id!==currentUser.id || anon) && (
            <span className="userName">{user.id===currentUser.id?"(You) ":""}{user.nick_name!=null?user.nick_name:user?.first_name}</span>
          )}
          
        </div>
        <div className="progressContainer">
          <ProgressBar name="Passrate" rate={passrate} />
          {currentUser.role <= 2 ? (
            <ProgressBar name="Conversation" rate={conversationRate} />
          ) : null}
        </div>
        {selectedCode?.creater_id === user.id ?<TerminalIcon/>:null}
      </div>
      {user.id===currentUser.id &&(
        <>
        <Switch
        checked={anon}
        onChange={()=>setAnon(!anon)}
        />
        <Typography sx={{marginTop:".55em"}} >Anonymous</Typography>
        </>

      )}
    </div>
    
  )
}

export default UserView
