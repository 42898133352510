import React, { createContext, useContext, useEffect, useState } from 'react'
import { SelectedUsersContext } from './SelectedUserContext'

export const SelectedCodeContext = createContext()

export const SelectedCodeProvider = ({ children }) => {
  const [selectedCode, setSelectedCode] = useState(null)
  const [rerender, setRerender] = useState(Date.now())
  const [keystrokes, setKeystrokes] = useState([])
  const [latestCodeId, setLatestCodeId] = useState('')
  const [output, setOutput] = useState('')
  const [isIdle, setIsIdle] = useState(false)
  const { selectedUsers } = useContext(SelectedUsersContext)
  const [currentCode, setCurrentCode] = useState(null)

  // useEffect(() => {
  //   if (selectedUsers && selectedUsers[0]) {
  //     setSelectedCode(selectedUsers[0].code)
  //   }
  // }, [selectedUsers])

  return (
    <SelectedCodeContext.Provider value={{ selectedCode, setSelectedCode, rerender, setRerender, keystrokes, setKeystrokes, latestCodeId, setLatestCodeId,output, setOutput, isIdle,setIsIdle, currentCode, setCurrentCode }}>
      {children}
    </SelectedCodeContext.Provider>
  )
}