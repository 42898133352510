import React, { useState, useContext } from 'react';
import Modal from '@mui/joy/Modal';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import Box from '@mui/joy/Box';
import CircularProgress from '@mui/joy/CircularProgress';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Alert from '@mui/joy/Alert';
import Button from '@mui/joy/Button';

import { AuthContext } from '../../context/AuthContext';
import { changeStudentPassword, updateStudentEmail } from '../../service/authService';

const SignUpModal = ({ open, onClose }) => {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const resetModalState = () => {
    setError(null);
    setSuccess(null);
    setEmail('');
    setPassword('');
    setConfirmPassword('');
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setLoading(true);

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      setLoading(false);
      return;
    }

    if (password.length < 6) {
      setError("Password should be at least 6 characters");
      setLoading(false);
      return;
    }

    try {
      // Update email
      if (email) {
        await updateStudentEmail(currentUser.email, email, currentUser.id);
      }

      // Update password
      await changeStudentPassword(null, password, currentUser.id);

      // Update the current user in context
      setCurrentUser(prevUser => ({
        ...prevUser,
        email: email || prevUser.email
      }));

      setSuccess('Account updated successfully');
      setTimeout(() => {
        onClose();
        // Optionally refresh the page or update the UI
      }, 2000);
    } catch (err) {
      console.error('Error during signup:', err);
      setError(err.message || "An error occurred during signup");
    } finally {
      setLoading(false);
    }
  };

  const renderField = (label, value, setter, type = "text") => (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Input
        type={type}
        value={value}
        onChange={handleInputChange(setter)}
        onKeyDown={handleKeyDown}
        required
      />
    </FormControl>
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        resetModalState();
      }}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        aria-labelledby="signup-modal-title"
        layout="center"
        size="sm"
        variant="outlined"
      >
        <Sheet
          sx={{
            borderRadius: 'md',
            p: 3,
            boxShadow: 'sm',
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: 'calc(-1/4 * var(--IconButton-size))',
              right: 'calc(-1/4 * var(--IconButton-size))',
              boxShadow: 'sm',
              borderRadius: '50%',
              bgcolor: 'background.surface',
            }}
          />
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography id="signup-modal-title" level="h4" sx={{ mb: 2, textAlign: 'center' }}>
                Complete Your Account
              </Typography>
              {success && (
                <Alert color="success" sx={{ mb: 2 }}>
                  {success}
                </Alert>
              )}
              {error && (
                <Alert color="danger" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}
              <form onSubmit={handleSignup}>
                <Box sx={{ display: 'grid', gap: 2 }}>
                  {renderField('Email', email, setEmail, "email")}
                  {renderField('Password', password, setPassword, "password")}
                  {renderField('Confirm Password', confirmPassword, setConfirmPassword, "password")}
                </Box>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                  <Button type="submit" variant="solid" color="primary">
                    Complete Sign Up
                  </Button>
                </Box>
              </form>
            </>
          )}
        </Sheet>
      </ModalDialog>
    </Modal>
  );
};

export default SignUpModal;