const ReviewQuestions = [
  {
    label: "Have you been regrouped?",
    description: "regrouped",
    inputType: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" }
    ],
  },
  {
    label: "Which group are you more satisfied with?",
    description: "more_satisfied_with",
    inputType: "radio",
    options: [
      { label: "First group", value: "firstGroup" },
      { label: "Second group", value: "secondGroup" },
      { label: "The same", value: "same" },
      { label: "Neither", value: "neither" }
    ],
  },
  {
    label: "Which group would you prefer to work with on future assignments?",
    description: "willing_to_work",
    inputType: "radio",
    options: [
      { label: "First group", value: "firstGroup" },
      { label: "Second group", value: "secondGroup" },
      { label: "The same", value: "same" },
      { label: "Neither", value: "neither" }
    ],
  },
  {
    label: "Tell us more (optional)",
    description: "additional_comment",
    inputType: "textfield",
    options: []
  }];

  const VizmentalReviewQuestions = [{
    label: "How easy was it to interact with the AI assistant?",
    description: "ease_of_interaction",
    inputType: "scale",
    options: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 }
    ],
  },
  {
    label: "How helpful was the AI assistant in solving programming problems?",
    description: "helpfulness",
    inputType: "scale",
    options: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 }
    ],
  },
  {
    label: "How engaged did you feel during the exercises with the AI assistant?",
    description: "engagement",
    inputType: "scale",
    options: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 }
    ],
  },
  {
    label: "Do you feel that using the AI assistant helped you understand the programming concepts better?",
    description: "understanding_improvement",
    inputType: "scale",
    options: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 }
    ],
  },
  {
    label: "How intuitive was the user interface of the tool?",
    description: "ui_intuitiveness",
    inputType: "scale",
    options: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 }
    ],
  },
  {
    label: "Any improvements you want to suggest? (optional)",
    description: "suggestions",
    inputType: "textfield",
    options: []
  }
];

const SphereReviewQuestions = [
  {
    label:"How do you feel about this exercise session?",
    description:"session_feeling",
    inputType:"multiple_choice",
    options:[
      {label:"I learned a lot", value: "I learned a lot"},
      {label:"I learned a little", value: "I learned a little"},
      {label:"Discussions were helpful", value: "Discussions were helpful"},
      {label:"I don't want to discuss", value: "I don't want to discuss"},
      {label:"I engaged a lot", value: "I engaged a lot"},
      {label:"I engaged a little", value: "I engaged a little"},
    ]
  },
  {
  label:"How do you think the instructor's feedback affects you?",
  description:"feedback_impact",
  inputType:"multiple_choice",
  options:[
    {label:"Increased my understanding", value:"Increased my understanding"},
    {label:"Made me confused", value:"Made me confused"},
    {label:"Encouraged me to join the discussion", value:"Encouraged me to join the discussion"},
    {label:"Discouraged me to join the discussion", value:"Discouraged me to join the discussion"},
    {label:"Make me engage more in the class", value:"Make me engage more in the class"},
    {label:"Make me engage less in the class", value:"Make me engage less in the class"},
    {label:"No impact", value:"No impact"},
    {label:"I don't know", value:"I don't know"}
  ]
},
{
  label: "Tell us more! (optional)",
  description: "additional_comment",
  inputType: "textfield",
  options: []
}
];

export {ReviewQuestions, VizmentalReviewQuestions, SphereReviewQuestions};
