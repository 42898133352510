import React, { useEffect, useContext, useState, useRef } from 'react';
import { MessageContext } from '../../context/MessageContext';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  TextField,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { HighlightMenu, MenuButton } from 'react-highlight-menu'
import {
  generateVariableQuestions,
  questionPatterns,
  defaultQuestions,
  generateErrorQuestions,
} from '../../component/student-components/data/CodeQuestions';
import { AuthContext } from '../../context/AuthContext';
import { SelectedCodeContext } from '../../context/SelectedCodeContext';

const PythonInterpreter = ({ output }) => {
  const outputRef = useRef();
  const { setChatMessage } = useContext(MessageContext);
  const { selectedCode, setSelectedCode } = useContext(SelectedCodeContext)

  // Declare states
  const [codeText, setCodeText] = useState('');
  const [open, setOpen] = useState(false);
  const [textField, setTextField] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [customQuestion, setCustomQuestion] = useState('');
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { currentUser } = useContext(AuthContext)

  const handleOpen = (selectedText, setMenuOpen) => {
    setCodeText(selectedText);

    const generatedQuestions = generateQuestions(selectedText);
    setQuestionList(generatedQuestions);

    setOpen(true);
    setMenuOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setTextField(false);
    setDisabledButton(false);
  };

  const handleSetMessage = (message, output) => {
    console.log(selectedCode)
    const formattedMessage = `${message} Here's the output:\n${output}`;
    setChatMessage(formattedMessage);
    setOpen(false);
  };

  const generateQuestions = (errorMessage) => {
      const errorQuestions = generateErrorQuestions(errorMessage);
  
      return errorQuestions.length > 0 ? errorQuestions : defaultQuestions;
  };
  

  return (
    <>
      {currentUser.role === 3 && (
        <HighlightMenu
          styles={{
            borderColor: '#1976d2',
            backgroundColor: 'white',
            boxShadow: '0px 5px 5px 0px rgba(21, 82, 146, 0.5)',
            zIndex: 10,
            borderRadius: '5px',
            padding: '3px',
          }}
          target={outputRef}
          menu={({ selectedText, setMenuOpen, setClipboard }) => (
            <>
              <IconButton onClick={() => handleOpen(selectedText, setMenuOpen)}>
              <QuestionMarkIcon sx={{ color: "#1976d2"}} />
              </IconButton>
            </>
          )}
        />
      )}
      <textarea ref={outputRef} className="codeOutputArea" value={output} readOnly />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="dialog-title" id="alert-dialog-title">
          {'Ask a Question'}
          <CloseIcon className="close-icon" onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <pre className="code-editor-style">{codeText}</pre>
            <List>
              {questionList.map((text, index) => (
                <ListItem
                  disablePadding
                  key={index}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <ListItemButton className="list-item-button" onClick={() => handleSetMessage(text, codeText)}>
                    <ListItemText primary={text} className="list-item-text" />
                    <ListItemIcon className="list-item-icon">
                      <CheckIcon className={`check-icon ${hoveredIndex === index ? 'hovered' : 'default'}`} />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              ))}
              {textField && (
                <ListItem disablePadding>
                  <TextField
                    id="standard-basic"
                    label="Your Question Here"
                    variant="standard"
                    value={customQuestion}
                    onChange={(e) => setCustomQuestion(e.target.value)}
                  />
                  <IconButton onClick={() => handleSetMessage(customQuestion, codeText)}>
                    <DoneIcon color="success" />
                  </IconButton>
                </ListItem>
              )}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PythonInterpreter;
